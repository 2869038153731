import NoResultBanner from './../../assets/animated_banners/search_no_result.gif';
const NoDataBanner =(props)=> {
    return(<div className="banner">
        <div className="text-center">
            <div>
                <img src={NoResultBanner} alt="no data found" />
            </div>
            <h5>
                No se han encontrado registros
            </h5>
            {
                
                (props.refresh === null || props.refresh === undefined)?
                null
                :
                <button onClick={(e)=>props.refresh(e)} className="btn btn-sm btn-success">Refresh</button>
            }
        </div>
    </div>
    )
}
export default NoDataBanner;