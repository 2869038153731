import {ReactComponent as AdwordsIcon} from "./../../assets/images/adwords.svg";
import {ReactComponent as AdRollIcon} from "./../../assets/images/adroll.svg";
import {ReactComponent as MicrosoftIcon} from "./../../assets/images/microsoft.svg";
import { useContext, useState } from "react";
import NetworkService from "../../services/NetworkService";
import AuthContext from "../../context/AuthContext";
const NetworkTiles=(props)=>{
    const {auth} = useContext(AuthContext);
    const [ isRelinking, setRelinking] = useState(false);
    const [ isDeleting, setDeleting ] = useState(0);
    const {
        ad_account_id,
        id,
        // is_active,
        // is_deleted,
        // linked_on,
        linked_status,
        // manager_status,
        // network_type,
        paid_details,
        type,
        // Sponsored_by,
        ad_network_name
    } = props.network;
    const deleteNetwork=(id)=>{
        // alert("deleting " + id)
        let networkService = new NetworkService();
        setDeleting(true);
        networkService.removeNetwork(id, (data)=>{
            if(data==="success"){
                alert("account deleted");
                props.refresh();
            }else{
                setDeleting(0);
            }
        })
    }
    
    const relinkAccount=()=>{
        let networkService = new NetworkService();
        setRelinking(true);
        networkService.relinkNetwork(id, (data)=>{
            if(data === "success"){
                alert("account relinked")
                props.refresh();
            }
            setRelinking(false);
        })
    }

    const unlinkAccount=()=>{
        let networkService = new NetworkService();
        setRelinking(true);
        networkService.unlinkNetwork(id, (data)=>{
            if(data === "success"){
                alert("account unlinked");
                props.refresh();
            }
            setRelinking(false);
        })
    }
    return(
        <div className="tile network" style={{opacity:isDeleting?"0.2":"1"}}>
            <div className="header">
                <div className="row">
                    <div className="col">
                        <ul className="module-options">
                            <li>
                                {/*<button onClick={()=>props.expandDetails(id)} className="btn btn-sm btn-default text-default">
                                    <i className="fa fa-square-o" aria-hidden="true"></i>
                                </button>*/
                                }
                            </li>
                            <li>
                                {
                                    (auth.userData.accountType === 3 || auth.userData.accountType === 4) &&
                                    (
                                        <button onClick={()=>deleteNetwork(id)} className="btn btn-sm btn-default text-default">
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    )
                                }
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="network-emblem">
                    {
                        type.toLowerCase() === "adwords" &&
                        <span><AdwordsIcon /></span>
                    }
                    {
                        type.toLowerCase() === "adroll" &&
                        <span><AdRollIcon /></span>
                    }
                    {
                        type.toLowerCase() === "microsoft" &&
                        <span><MicrosoftIcon /></span>
                    }
                </div>
            </div>
            <div className="body text-center">
                <h6>{ad_account_id}</h6>
                <p>{ad_network_name}</p>
            </div>
            <div className="footer">
                <div>
                    <div>Estado</div>
                    <b className="text-success">
                        {
                            linked_status === "L" ?
                            <>
                                <span className="text-success">Vinculada</span>
                                {
                                    (auth.userData.accountType === 3 || auth.userData.accountType === 4) &&
                                    (
                                        <lable 
                                            //onClick={()=>unlinkAccount()}
                                            //title="Click to unlink"
                                            className="btn-sm btn-default"
                                        >
                                            
                                            <i  ame={isRelinking?"fa fa-refresh fa-spin text-danger" : "fa fa-link text-success"} aria-hidden="true"></i>
                                        </lable>
                                    )
                                }
                            </>
                            :
                            <>
                                <span className="text-danger">Sin vincular</span>
                                {
                                    (auth.userData.accountType === 3 || auth.userData.accountType === 4) && 
                                    (
                                       <lable 
                                            //onClick={()=>relinkAccount()}
                                            //title="Click to link"
                                            className="btn-sm btn-default"
                                        >
                                            <i className={isRelinking?"fa fa-refresh fa-spin text-success" : "fa fa-unlink text-danger"} aria-hidden="true"></i>
                                        </lable>
                                        
                                    )
                                    }
                            </>
                        }
                    </b>
                </div>
                {/* <div>
                    <div>Placements</div>
                    <b>3983</b>
                </div> */}
                <div>
                    <div>Patrocinada por</div>
                    <b>
                        {
                            paid_details !== null && paid_details.length > 0 ?
                            <span>
                                {paid_details[0].sponsor}
                                {
                                    paid_details.length > 1 && (" and " + (paid_details.length - 1) + " more")
                                }
                            </span>
                            :
                            "No Sponsor"
                        }
                    </b>
                </div>
            </div>
        </div>
    )
}

export default NetworkTiles;