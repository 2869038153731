import Service from './Service';
class ConfigService extends Service{
    async getAllCountries(callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}st_country/getAll`,
            {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }

    async getAllStates(countryId, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}st_state/getByCountryId/${countryId}`,
            {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.json();
        }).then((data)=>{
            callback(data);
        });
    }

    async getAllCity(stateId, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}st_city/getByStateId/${stateId}`,
            {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.json();
        }).then((data)=>{
            callback(data);
        });
    }
}

export default ConfigService;