import React from 'react';
import { Switch } from 'react-router-dom';
import $ from 'jquery';
import Logo from '../../components/Logo';

import { ReactComponent as BurgerIcon } from './../../assets/icons/burger_icon.svg';
import Home from '../../modules/Home';
import BlockList from '../../modules/BlockList';
import { NavLink, Route } from 'react-router-dom';
import Support from '../../modules/Support';
import UserInfoCard from '../../components/UserInfoCard';
import AdNetworks from '../../modules/AdNetworks';
import Invoice from '../../modules/Invoice';
import Setting from '../setting/Setting';
import NetworkService from '../../services/NetworkService';
import AuthContext from './../../context/AuthContext';
import Users from '../../modules/Users'


class Dashboard extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.toggleNav = this.toggleNav.bind(this);
        this.setAppHeading = this.setAppHeading.bind(this);
        this.logout = this.logout.bind(this);
        this.state = {
            isExpanded: true,
            heading: "Welcome",
            headingAnimationClass: "",
            networkList: {},
        }
    }
    componentDidMount() {
        let networkService = new NetworkService();
        networkService.getNetworks((data) => {
            let networkList = this.state.networkList;
            networkList = data;
            this.setState({
                networkList: networkList
            });
        })
    }

    render() {
        const { isExpanded, heading, headingAnimationClass } = this.state;
        let navStyle = {};
        if (isExpanded) {
            navStyle = {
                width: "300px",
            }
        } else {
            navStyle = {
                width: "44px",
            }
        }
        return (
                <div className="container-fluid container-ext">
                    <div className="dashboard">
                        <nav style={navStyle} className={`${isExpanded ? "highlight-background" : "active highlight-background"}`}>
                            <div className="header-container">
                                <div className="header">
                                    <div className="row">
                                        <div className="col hidable">
                                            <Logo />
                                        </div>
                                        <div className="col text-right">
                                            <div className={`menu-trigger ${isExpanded ? "" : "active"}`}>
                                                <BurgerIcon onClick={this.toggleNav} className="burger-icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <UserInfoCard />
                                </div>
                                <ul>
                                    <li>
                                        <NavLink exact activeClassName="active" to="/adblock/home">
                                            <span className="icon sm">
                                                <i className="fa fa-lemon-o" aria-hidden="true"></i>
                                            </span>
                                            <label className="hidable">Inicio</label>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/adblock/networks">
                                            <span className="icon sm">
                                                <i className="fa fa-square" aria-hidden="true"></i>
                                            </span>
                                            <label className="hidable">Cuentas publicitarias</label>
                                            {/* <span className="control-icon icon sm  hidable">
                                        <ArrowIcon />
                                    </span> */}
                                        </NavLink>
                                    </li>
                                    {
                                        this.context.auth.userData.accountType === 1 &&
                                        <li>
                                            <NavLink activeClassName="active" to="/adblock/users">
                                                <span className="icon sm">
                                                    <i className="fa fa-users" aria-hidden="true"></i>
                                                </span>
                                                <label className=" hidable">Users</label>
                                                {/* <span className="control-icon icon sm hidable">
                                            <ArrowIcon />
                                        </span> */}
                                            </NavLink>
                                        </li>
                                    }
                                    <li>
                                        <NavLink activeClassName="active" to="/adblock/blocklist">
                                            <span className="icon sm">
                                                <i className="fa fa-ban" aria-hidden="true"></i>
                                            </span>
                                            <label className=" hidable">Lista de excluidos</label>
                                            {/* <span className="control-icon icon sm hidable">
                                        <ArrowIcon />
                                    </span> */}
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink activeClassName="active" to="/adblock/invoice">
                                            <span className="icon sm">
                                                <i className="fa fa-money" aria-hidden="true"></i>
                                            </span>
                                            <label className=" hidable">Invoice</label>
                                        </NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink activeClassName="active" to="/adblock/support">
                                            <span className="icon sm">
                                                <i className="fa fa-support" aria-hidden="true"></i>
                                            </span>
                                            <label className=" hidable">Soporte</label>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/adblock/setting">
                                            <span className="icon sm">
                                                <i className="fa fa-cog" aria-hidden="true"></i>
                                            </span>
                                            <label className=" hidable">Configuración</label>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <button
                                    className="btn btn-sm">
                                    <span style={{margin:'4px', display:'inline-block'}} onClick={this.logout} className="icon sm">
                                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                        </nav>

                        <section>
                            <div className="container-fluid">
                                <div className="dashboard-header">
                                    <div className="row">
                                        <div className="col">
                                            <h3 className={`${headingAnimationClass} animate app-heading`}>{heading}</h3>
                                        </div>
                                        {/* <div className="col-md-2">
                                        <form>
                                            <div className="form-group">
                                                <select
                                                    className="form-control form-control-sm"
                                                >
                                                    <option>--select--</option>
                                                    <option>All</option>
                                                    <option>AdWords</option>
                                                    <option>AdRoll</option>
                                                    <option>Microsoft Ads</option>
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-2">
                                        <form>
                                            <div className="form-group">
                                                <select
                                                    className="form-control form-control-sm"
                                                >
                                                    <option>--select--</option>
                                                    <option>All</option>

                                                </select>
                                            </div>
                                        </form>
                                    </div> */}
                                    </div>
                                </div>
                                {/* Module Switch */}
                                <div>
                                    <Switch>
                                        <Route path="/adblock/blocklist">
                                            <BlockList setAppHeading={this.setAppHeading} />
                                        </Route>
                                        <Route path="/adblock/users">
                                            <Users setAppHeading={this.setAppHeading} />
                                        </Route>
                                        <Route path="/adblock/home">
                                            <Home setAppHeading={this.setAppHeading} />
                                        </Route>
                                        <Route path="/adblock/support">
                                            <Support setAppHeading={this.setAppHeading} />
                                        </Route>
                                        <Route path="/adblock/networks">
                                            <AdNetworks setAppHeading={this.setAppHeading} />
                                        </Route>
                                        <Route path="/adblock/invoice">
                                            <Invoice setAppHeading={this.setAppHeading} />
                                        </Route>
                                        <Route path="/adblock/setting">
                                            <Setting setAppHeading={this.setAppHeading} />
                                        </Route>
                                    </Switch>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
        )
    }

    // ops
    toggleNav(event) {
        event.preventDefault();
        let { isExpanded } = this.state;
        if (isExpanded) {
            $(".hidable").fadeOut(200);
            setTimeout(() => {
                isExpanded = !isExpanded;
                this.setState({
                    isExpanded: isExpanded
                })
            }, 400)
        } else {
            isExpanded = !isExpanded;
            this.setState({
                isExpanded: isExpanded
            })
            setTimeout(() => {
                $(".hidable").fadeIn(200);
            }, 400)
        }
    }
    setAppHeading(appHeading) {
        let { heading, headingAnimationClass } = this.state;
        let intervalCounter = 0;
        const animationInterval = setInterval(() => {
            intervalCounter++;
            switch (intervalCounter) {
                case 1: {
                    headingAnimationClass = "fade-out";
                    break;
                }
                case 3: {
                    heading = appHeading;
                    break;
                }
                case 4: {
                    headingAnimationClass = "fade-in";
                    break;
                }
                case 5: {
                    clearInterval(animationInterval);
                    break;
                }
                default:
                    break;
            }
            this.setState({
                heading: heading,
                headingAnimationClass: headingAnimationClass
            })
        }, 200)
    }
    logout(event) {
        event.preventDefault();
        const { setAuth } = this.context;
        localStorage.removeItem("token");
        setAuth({
            isLoggedIn: false,
            userData: undefined,
        })
    }
}
export default Dashboard;
