import StorageManager from '../resource/StorageManager';
import Service from './Service'
import CryptoJS from "crypto-js";

class LoginService extends Service{
//class LoginService {

    async authenticate(userCred, callback){
        userCred.password = CryptoJS.MD5(userCred.password).toString();
        // const access_point = process.env.ACCESS_POINT;
	console.log('acces point : ',process.env.REACT_APP_ACCESS_POINT)
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/userAuthentication`,
            {
                method: 'POST',
                mode:'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(userCred),
            }
        )
        .then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                //Service.HandleResponseError(response);
                this.handleResponseError(response);
            }   
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }
    
    async getUserDetails(callback){
        const storageManager = new StorageManager();
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/getUserDetails`,{
            method:"GET",
            mode:"cors",
            redirect: 'follow',
            headers:{
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${storageManager._get('token')}`,
            }
        }).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                //Service.HandleResponseError(response);
                this.handleResponseError(response);
            }
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }
}
export default LoginService;