import React from 'react';

const DeadTableRow = (props) =>{
    return (
        <div className="table-row">
            <div className="row dead-row">
                <div className="col-md-1">
                    <label>{props.index}</label>
                </div>
            </div>
        </div>
    )
}

export default DeadTableRow;