import { Component } from "react";
import UserService from "../../services/UserService";
import NoDataBanner from "../banners/NoDataBanner";
import React from 'react';
import PaginationPoints from './../other/PaginationPoint';

class UserTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queryList: {
                totalRecords: 0,
                pageNo: 1,
                rows: 0,
                data: []
            },
            status: 0,
            id: 0,
        }
        this.getData({
            id: 2,
            status: 0,
            pageNo: 1,
        });
        this.toggleFilterType = this.toggleFilterType.bind(this);
        this.changeSet = this.changeSet.bind(this);
    }
    getData = (req) => {
        let userService = new UserService();
        userService.getUserByType(req, (response) => {
            this.setState({
                queryList: response
            });
        });
    }

    // changeSet = (setNumber) => {
    //     if(pageNo !== setNumber){
    //         let request = {
    //             pageNo: setNumber,
    //         }
    //     }
    // }
    refresh = (type) => {
        this.setState({
            id: type
        }, ()=>{
            this.getData({
                id: this.state.id,
                status: this.state.status,
                pageNo: this.state.queryList.pageNo,
            });

        })
    }
    // const paginationPoints = [];
    // if(totalRecords > rows){
    //     for (let index = Math.max(1, pageNo-5) ; index <= Math.min(pageNo+5, Math.ceil(totalRecords/rows)); index++) {
    //         let isActive = (pageNo === index) ? "active" : ""
    //         paginationPoints.push(<PaginationPoints onClick={changeSet.bind(null, index)} isActive={"" + isActive} setNumber={index} />);
    //     }
    // }
    changeSet = (setNumber) => {
        let pageNo = this.state.queryList.pageNo;
        this.setState({
            pageNo: pageNo
        },()=>{
            this.getData({
                id: this.state.id,
                status: this.state.status,
                pageNo: this.state.queryList.pageNo,
            })
        });
    }
    toggleFilterType = (type) => {
        let status = this.state.status;
        if (type !== status) {
            this.setState({
                status: type
            },()=>{
                this.getData({
                    id: this.state.id,
                    status: this.state.status,
                    pageNo: this.state.queryList.pageNo,
                })
            })

        }
    }
    render() {
        const { totalRecords, pageNo, rows, data } = this.state.queryList;
        console.log(data);
        const paginationPoints = [];
        if (totalRecords > rows) {
            for (let index = Math.max(1, pageNo - 5); index <= Math.min(pageNo + 5, Math.ceil(totalRecords / rows)); index++) {
                let isActive = (pageNo === index) ? "active" : ""
                paginationPoints.push(<paginationPoints onClick={()=>this.changeSet.bind(null, index)} isActive={"" + isActive} setNumber={index} />);
            }
        }
        return (
            <React.Fragment>
                {
                    
                        <div className="table-container">
                            <div className="row">
                                <div className="col">
                                    <div className="col">
                                        <div class="switch-field">
                                            <input onClick={() => this.toggleFilterType(0)} type="radio" checked={this.state.status === 0 ? true : null} id="fiterTypeActive" name="filterType" value="pending" />
                                            <label htmlFor="fiterTypeActive">New</label>
                                            <input onClick={() => this.toggleFilterType(1)} type="radio" checked={this.state.status === 1 ? true : null} id="fiterTypePending" name="filterType" value="active" />
                                            <label htmlFor="fiterTypePending">Existing</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-right">
                                    <select className='form-control' onChange={(event) => this.refresh(event.target.value)}>
                                        <option value='2'>Sub Admin</option>
                                        <option value='3'>Brand</option>
                                        <option value='4'>Ad Agency</option>
                                    </select>
                                </div>
                            </div>
                            {
                            data.length > 0 ?
                            <div className="table">
                                <div className="table-header">
                                    <div className="row">
                                        <div className="col-md-1">Sr. No.</div>
                                        <div className="col-md-6">Account Name</div>
                                        <div className="col-md-4">Action</div>
                                    </div>
                                </div>
                                <div className="table-option-list"></div>
                                <div className="table-body">
                                    {
                                        data.map((ele, index) => {
                                            return (
                                                <div key={"table_row_index" + index} className="table-row ">
                                                    <div key={"queryList_" + index} className="row ">
                                                        <div className="col-md-1">
                                                            <label>{index + 1}</label>
                                                        </div>  
                                                        <div className="col-md-6 placement-value">
                                                            <label title={ele.account_name}>{ele.account_name}</label>
                                                        </div>
                                                        <div className='col col-md-5'>
                                                            <button className='btn btn-sm btn-success'>Activate</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div className="table-footer">
                                    <div className="row pagination">
                                        <div className="col">
                                            <ul >
                                                {
                                                    totalRecords > rows &&
                                                    <li>
                                                        <button onClick={()=>this.changeSet.bind(null, 1)} className="btn btn-link">first</button>
                                                    </li>
                                                }
                                                {
                                                    paginationPoints
                                                }
                                                {
                                                    totalRecords > rows &&
                                                    <li>
                                                        <button onClick={()=>this.changeSet.bind(null, Math.ceil(totalRecords / rows))} className="btn btn-link">last</button>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <NoDataBanner />
                            }
                        </div>
                        

                }
            </React.Fragment>
        )
    }
}
export default UserTable;