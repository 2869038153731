import React, { Component } from "react";
import { Link, Route, Switch } from "react-router-dom";
import BlockListTable from "../components/tables/BlockListTable";
import UploadBlocklist from "../components/UploadBlocklist"
import AuthContext from './../context/AuthContext'
class BlockList extends Component{
    static contextType = AuthContext;
    constructor(props){
        super(props);
        this.state={
            moduleHeading: "Bloqueo reciente",
            headingAnimationClass: "",
            isMainModule: true,
        }
    }
    componentDidMount(){
        this.props.setAppHeading("Tipo de lista de excluidos");
    }
    setModuleHeading=(heading)=>{
        if(heading !== "Bloqueo reciente"){
            this.setState({
                isMainModule: false
            });
        }else{
            this.setState({
                isMainModule: true
            });
        }
        let {moduleHeading, headingAnimationClass} = this.state;
        let intervalCounter = 0;
        const animationInterval = setInterval(()=>{
            intervalCounter++;
            switch (intervalCounter) {
                case 1:{
                    headingAnimationClass="fade-out";
                    break;
                }
                case 3:{
                    moduleHeading = heading;                
                    break;
                }
                case 4:{
                    headingAnimationClass="fade-in";
                    break;
                }
                case 5:{
                    clearInterval(animationInterval);
                    break;
                }
                default:
                    break;
            }
            this.setState({
                moduleHeading: moduleHeading,
                headingAnimationClass: headingAnimationClass
            })
        }, 200)
    }
    render(){

        const {moduleHeading, headingAnimationClass, isMainModule} = this.state;
        return(
            <div className="module">
                <div className="module-header">
                    <div className="row  d-flex align-items-center justify-content-center">
                        <div className="col">
                            <h5 className={`${headingAnimationClass} animate semi-heading `}>{moduleHeading}</h5>
                        </div>
                        {
                            (this.context.auth.AccountType === 1 || this.context.auth.AccountType === 2) &&
                            (
                                <div className="col text-right">
                                    {
                                        !isMainModule &&
                                        <Link to="/adblock/blocklist/">Cancel</Link>
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="module-body">
                    <Switch>
                        <Route path="/adblock/blocklist/upload">
                            <UploadBlocklist setModuleHeading={this.setModuleHeading} />
                        </Route>
                        <Route path="/adblock/blocklist">
                            <BlockListTable setModuleHeading={this.setModuleHeading} />
                        </Route>
                    </Switch>
                </div>
            </div>
        )
    }
}
export default BlockList;