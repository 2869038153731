import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";


const AccountTypeSelectionForm = (props) => {
    const { handleSubmit} = useForm();
    const [selectedAccountType, setAccountType] = useState();
    const submit=(data, event)=>{
        props.selectAccountType(selectedAccountType);
    }
    return (
        <div className="fullscreen d-flex align-items-center justify-content-center">
            <div className="form-container">
                <h5>Selecciona el tipo de cuenta</h5>
                <p>¿Deseas crear una cuenta para Creador de contenido o Marca?</p>
                <div className="form">
                    <form onSubmit={handleSubmit(submit)} noValidate autoComplete="false">
                        <div className="row">
                            <div className="col text-center">
                                <label className="d-block" htmlFor="inputChoiceContentOwner">
                                    <input
                                        className="emblem option content-owner"
                                        type="radio"
                                        value="Content Owner"
                                        id="inputChoiceContentOwner"
                                        name="accountType"
                                        aria-describedby="Conent Owner"
                                        onClick={() => setAccountType(2)}
                                    />
                                    <div>Content Owner</div>
                                </label>
                            </div>
                            <div className="col text-center">
                                <label className="d-block" htmlFor="inputChoiceBrand">
                                    
                                    <input
                                        className="emblem option brand"
                                        type="radio"
                                        value="Brand"
                                        id="inputChoiceBrand"
                                        name="accountType"
                                        aria-describedby="Brand"
                                        onClick={() => setAccountType(3)}
                                    />
                                    <div>Brand</div>
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            
                            <div className="col text-right">
                                <button
                                    type="submit"
                                    disabled={selectedAccountType > 0 ? null : true}
                                    className="btn btn-sm btn-success">
                                    Siguiente
                                </button>
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            {
                                selectedAccountType === 2 ?
                                    <div className="col">
                                        <div className="fixed-width fade show alert alert-info">
                                            <strong>Has seleccionado Propietario del contenido</strong>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                selectedAccountType === 3 ?
                                <div className="col">
                                    <div className="fixed-width fade show alert alert-info">
                                        <strong>Has seleccionado Marca</strong>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col">
                                <Link className="btn btn-sm btn-link compact-link" to="/login">Inicio de sesión</Link>
                            </div>
                            <div className="col text-right">
                                <Link className="btn btn-sm btn-link compact-link" to="/login">Visita nuestro sitio web</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AccountTypeSelectionForm;