import Service from './Service';
class SuppportService extends Service{
    async getAllQuery(req, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_query/showAllTicket/${req.userId}/${req.pageNo}`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }   
            return response.json();
        }).then((data)=>{
            console.log(data);
            callback(data);
        })
    }

    
    async storeQuery(query, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_query/addNewTicket`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(query)
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }

    async markResolved(query, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_query/updateResolveStatus/`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(query)
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.text();
        }).then((data)=>{
            callback(data);
        })
    }
}
export default SuppportService;