import { useEffect, useState } from 'react';
import SponsorService from '../../services/SponsorService';
import NoDataBanner from '../banners/NoDataBanner';
import DeadTableRow from '../DeadTableRow';
import MiniLoader from '../other/MiniLoader';
import PaginationPoint from '../other/PaginationPoint';
import { useForm } from 'react-hook-form';

const SponsorAdminPanel = props => {
    const [filterType, setFilterType] = useState(0);
    const [setModuleData] = useState();
    const { register, handleSubmit, errors, setValue } = useForm(0);
    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [queryList, setQueryList] = useState({
        totalRecords: 0,
        pageNo: 1,
        rows: 0,
        data: [
        ]
    });
    useEffect(() => {
        let sponsorService = new SponsorService();
        const pageRequest = {
            //type: 'Pending',
            type: 'Active',
            pageNo: 1,
            search: ''
        }
        sponsorService.getSponsorsByType(pageRequest, (data) => {
            setQueryList(data);
        })
    }, []);
    const clearSearch = () => {
        setSearch("");
        let sponsorService = new SponsorService();
        const pageRequest = {
            //type: 'Pending',
            type: 'Active',
            pageNo: 1,
            search: ''
        }
        sponsorService.getSponsorsByType(pageRequest, (data) => {
            //console.log(data);
            setQueryList(data);
        })
    }
    const toggleFilterType = (type) => {
        if (type !== filterType) {
            setFilterType(type);
            const ty = type === 0 ? "Active" : "Revoke";
            let sponsorService = new SponsorService();
            const pageRequest = {
                type: ty,
                pageNo: 1
            };

            sponsorService.getSponsorsByType(pageRequest, (data) => {
                setQueryList(data)
                setLoading(false);
            })
        }
    }
    const { totalRecords, pageNo, rows, data } = queryList;
    const deadRows = [];
    if (queryList.data.length < queryList.rows) {
        for (let index = queryList.data.length; index < 10; index++) {
            deadRows.push(<DeadTableRow key={"dead_row_queryList_" + index} index={index + 1} />);
        }
    }
    const handleSubmitReq = (id, state) => {
        let sponsorDetails = [
            {
                id: id,
                status: state
            }
        ];
        //Replace gere from 64 to 71
        /*
        let sponsorService = new SponsorService();
        sponsorService.updateSponsorStatus(sponsorDetails, (response) => {
            let sponsorService = new SponsorService();
            sponsorService.getSponsorsByType("Pending", (data) => {
                setModuleData(data);
                setLoading(false);
            })
        })*/
        let sponsorService = new SponsorService();
        sponsorService.updateSponsorStatus(sponsorDetails, (response) => {
            console.log('sponsorDetails.status ', sponsorDetails[0].status)
            let sponsorService = new SponsorService();
            let sState = 'Revoke'
            if (sponsorDetails[0].status == 'Revoke') {
                sState = 'Active'
            }
            sponsorService.getSponsorsByType(
                {
                    type: sState,
                    pageNo: queryList.pageNo
                }
                , (data) => {
                    setQueryList(data);
                    setLoading(false);
                })
        })

    }
    const changeSet = (setNumber) => {

        //alert(filterType)

        if (pageNo !== setNumber) {
            let pageRequest = {
                pageNo: setNumber,
                type: filterType === 0 ? "Active" : "Revoke",
                search: search
            }
            let sponsorService = new SponsorService();
            sponsorService.getSponsorsByType(pageRequest, (data) => {
                setQueryList(data);
                console.log('pageRequest ', pageRequest)
                console.log('data ', data)
            })

        }
    }
    const searchByKeyword = (data, event) => {
        event.preventDefault();
        setLoading(true);
        let pageRequest = {
            pageNo: 1,
            type: filterType === 0 ? "Active" : "Revoke",
            search: search
        }
        let sponsorService = new SponsorService();
        sponsorService.getSponsorsByType(pageRequest, (data) => {
            setQueryList(data);
            setLoading(false);
        })

    }
    const paginationPoints = [];
    if (totalRecords > rows) {
        for (let index = Math.max(1, pageNo - 5); index <= Math.min(pageNo + 5, Math.ceil(totalRecords / rows)); index++) {
            let isActive = (pageNo === index) ? "active" : ""
            paginationPoints.push(<PaginationPoint onClick={changeSet.bind(null, index)} isActive={"" + isActive} setNumber={index} />);
        }
    }
    return (<div>
        <div>
            <div className="row">
                <div className="col">
                    <div class="switch-field">
                        <input onClick={() => toggleFilterType(0)} type="radio" checked={filterType === 0 ? true : null} id="fiterTypePending" name="filterType" value="active" />
                        <label htmlFor="fiterTypePending">Existente</label>
                        <input onClick={() => toggleFilterType(1)} type="radio" checked={filterType === 1 ? true : null} id="fiterTypeActive" name="filterType" value="revoke" />
                        <label htmlFor="fiterTypeActive">Revocado</label>

                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col">
                    <form onSubmit={handleSubmit(searchByKeyword)}>
                            <div className="row">
                                <div className="col">
                                    <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        name="query"
                                        value={search}
                                        placeholder="Buscar aquí                                    "
                                        onChange={(e)=>setSearch(e.target.value)}
                                        ref={
                                            register({
                                                required:{
                                                    value:true,
                                                    message:"Introduce alguna palabra clave.                                                "
                                                }
                                            })
                                        }
                                    />
                                </div>
                                <div className="col">
                                    <button type="submit" className="btn btn-success btn-sm">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                    <button onClick={()=>clearSearch()} type="reset" className="btn btn-light btn-sm">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            {
                                errors.query
                                &&
                                <small className="text-danger">
                                    {errors.query.message}
                                </small>
                            }

        </form>
                    </div>

                </div>
            </div>
        </div>
        {
            isLoading ?
                <MiniLoader message="Loading" />
                :
                <div>
                    {
                        data.length > 0 ?
                            <div>

                                <div className="table-container">
                                    <div className="table">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="col-md-3">Nombre de cuenta</div>
                                                <div className="col-md-2">Tipo de cuenta</div>
                                                <div className="col-md-2">Identificación de la cuenta</div>
                                                <div className="col-md-3">Dirección de correo electrónico</div>
                                                <div className="col-md-2">Acción</div>
                                            </div>
                                        </div>
                                        <div className="table-option-list"></div>
                                        <div className="table-body">
                                            {
                                                queryList.data.map((ele, index) => {
                                                    return (
                                                        <div key={"table_row_index" + index} className="table-row foldable">
                                                            <div key={"queryList_" + index} className="row preview-row">
                                                                <div className="col-md-3">
                                                                    <label>{ele.account_name}</label>
                                                                </div>
                                                                <div className="col-md-2 placement-value">
                                                                    {ele.type}
                                                                </div>
                                                                <div className="col-md-2">
                                                                    {ele.ad_account_id}
                                                                </div>
                                                                <div className="col-md-3">
                                                                    {ele.email_address}
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    {
                                                                        ele.status.toLowerCase() === "revoke" ?
                                                                            <>
                                                                                <button onClick={() => handleSubmitReq(ele.id, "Active")} className="btn btn-sm btn-success">Activo</button>
                                                                                {/*&nbsp;
                                                        <button onClick={() => handleSubmitReq(ele.id, "Reject")} className="btn btn-sm btn-danger">Rechazo</button>*/}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <button onClick={() => handleSubmitReq(ele.id, "Revoke")} className="btn btn-sm btn-danger">Revocar</button>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* Adding dead rows */}
                                            {
                                                deadRows
                                            }
                                        </div>
                                        <div className="table-footer">
                                            <div className="row pagination">
                                                <div className="col">
                                                    <ul >
                                                        {
                                                            totalRecords > rows &&
                                                            <li>
                                                                <button onClick={changeSet.bind(null, 1)} className="btn btn-link">primera</button>
                                                            </li>
                                                        }
                                                        {
                                                            paginationPoints
                                                        }
                                                        {
                                                            totalRecords > rows &&
                                                            <li>
                                                                <button onClick={changeSet.bind(null, Math.ceil(totalRecords / rows))} className="btn btn-link">última</button>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <NoDataBanner />
                    }
                </div>
        }
    </div>)
}

export default SponsorAdminPanel;
