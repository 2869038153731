import Service from "./Service";

class SettingService extends Service {
    async getPersonalDetails(callback) {
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/getPersonalDetails`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "accept-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data) => {
                callback(data);
            })
    }

    async getBillingDetails(callback) {
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/getBillingDetails`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "accept-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data) => {
                callback(data);
            })
    }

    async updatePersonalDetails(user, callback){
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/updatePersonalDetails`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "accept-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(user)
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data) => {
                callback(data);
            })
    }

    async updateBillingDetails(billing, callback){
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/updateBillingDetails`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "accept-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(billing)
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data) => {
                callback(data);
            })
    }
}
export default SettingService;