import React, { useEffect, useState } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
//import * as am4lang_de_DE from "@amcharts/amcharts4/lang/am4lang_de_DE"
import am4lang_de_DE from "@amcharts/amcharts4/lang/de_DE";
import ReportService from '../services/ReportService';
import DeadTableRow from '../components/DeadTableRow';
import PaginationPoints from '../components/other/PaginationPoint';
import { useForm } from 'react-hook-form';
import NoDataBanner from './../components/banners/NoDataBanner'

const DashboardReports = (props) => {

    const [queryList, setqueryList] = useState({
        data: [],
        pageNo: 0,
        rows: 0,
        totalRecords: 0
    });
    const [queryString, setQueryString] = useState('');
    const {register, handleSubmit, errors, setValue} = useForm(0);
    useEffect(() => {
        let chart1, chart2 = null;
        let reportService = new ReportService();
        reportService.getPieChart((data) => {
            let chart1 = am4core.create("chartdivPieChart", am4charts.PieChart);
            chart1.numberFormatter.language = new am4core.Language();
            chart1.numberFormatter.language.locale = am4lang_de_DE;
            chart1.data = data;
            let series = chart1.series.push(new am4charts.PieSeries());
            series.labels.template.bent = true;
            series.labels.template.disabled = true;

            series.alignLabels = false;
            series.labels.template.fontSize = 11;
            series.dataFields.value = "count";
            series.dataFields.category = "type";
            //series.slices.template.fill = am4core.color("#0b4cb4");
            chart1.radius = am4core.percent(80);
        })

        reportService.getBarGraph((data) => {
            let chart2 = am4core.create("chartdivBarChart", am4charts.XYChart);
            chart2.numberFormatter.language = new am4core.Language();
            chart2.numberFormatter.language.locale = am4lang_de_DE;
            chart2.language.locale = am4lang_de_DE;
            chart2.data = data;

            let categoryAxis = chart2.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "domain";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;

            categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
                if (target.dataItem && target.dataItem.index & 2 == 2) {
                    return dy + 25;
                }
                return dy;
            });
            chart2.yAxes.push(new am4charts.ValueAxis());
            categoryAxis.renderer.labels.template.disabled = true;
            let series = chart2.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = "adSpent";
            series.dataFields.categoryX = "domain";
            series.name = "AdSpent";
            series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
            series.columns.template.fillOpacity = .8;

            let columnTemplate = series.columns.template;
            columnTemplate.strokeWidth = 1;
            columnTemplate.strokeOpacity = 0;
            //columnTemplate.fill = am4core.color("#0b4cb4");
        })

        reportService.getTabularData({pageNo:1, query:''}, (data) => {
            setqueryList(data);
        });


        return (() => {
            if (chart1) {
                chart1.dispose();
            }
            if (chart2) {
                chart2.dispose();
            }
        })
    }, [])
    const changeSet = (setNumber) => {
        if (pageNo !== setNumber) {
            let reportService = new ReportService();
            let page = {
                pageNo: setNumber,
                query: ""+ queryString
            }
            reportService.getTabularData(page, (data) => {
                setqueryList(data);
            });
        }
    }
    const { totalRecords, pageNo, rows, data } = queryList;
    const deadRows = [];
    if (queryList.data.length < queryList.rows) {
        for (let index = queryList.data.length; index < 10; index++) {
            deadRows.push(<DeadTableRow key={"dead_row_queryList_" + index} index={index + 1} />);
        }
    }
    const paginationPoints = [];
    if (totalRecords > rows) {
        for (let index = Math.max(1, pageNo - 5); index <= Math.min(pageNo + 5, Math.ceil(totalRecords / rows)); index++) {
            let isActive = (pageNo === index) ? "active" : ""
            paginationPoints.push(<PaginationPoints key={'pagination_point_' + index} onClick={changeSet.bind(null, index)} isActive={"" + isActive} setNumber={index} />);
        }
    }
    const submit=(data, event)=>{
        event.preventDefault();
        let page = {
            pageNo: 1,
            query: ""+ data.query
        }
        let reportService = new ReportService();
        reportService.getTabularData(page, (data) => {
            setqueryList(data);
        });

    }
    const clearSearch=()=>{
        setQueryString("");
        let page = {
            pageNo: 1,
            query: ""
        }
        let reportService = new ReportService();
        reportService.getTabularData(page, (data) => {
            setqueryList(data);
        });
    }
    return (
        <div className="reports">
            <div className="row">
                <div className="col">
                    <h5 className="semi-heading">Informes</h5>
                </div>
                <div className="col"></div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="chart-container">
                        <h5 className="semi-heading">
                        Tipo de lista de excluidos
                        </h5>
                        <div>
                            <div id="chartdivPieChart" style={{ width: "100%", height: "400px" }}></div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="chart-container">
                        <h5 className="semi-heading">
                        Principales dominios de piratería
                        </h5>
                        <div>
                            <div id="chartdivBarChart" style={{ width: "100%", height: "400px" }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="chart-container overflow" style={{ overflowX: "scroll" }}>
                        <h5 className="semi-heading">
                        Resumen
                        </h5>
                        <div className="table-container">
                            
                                    <form onSubmit={handleSubmit(submit)}>
                                        <div className="row">
                                            <div className="col">
                                                <input
                                                    onChange={(e)=>setQueryString(e.target.value)}
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    name="query"
                                                    placeholder="Buscar aquí                                                    "
                                                    ref={
                                                        register({
                                                            required: {
                                                                value: true,
                                                                message: "Introduce alguna palabra clave.                                                                "
                                                            }
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="col">
                                                <button type="submit" className="btn btn-success btn-sm">
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                </button>
                                                <button onClick={() => clearSearch()} type="reset" className="btn btn-light btn-sm">
                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            errors.query
                                            &&
                                            <small className="text-danger">
                                                {errors.query.message}
                                            </small>
                                        }
                                    </form>
                                
                            {
                                data.length > 0 ?
                                <div className="table compact">
                                <div className="table-header">
                                    <div className="table-row">
                                        <div className="row" style={{ flexWrap: "nowrap" }}>
                                            <div className="col-md-2">Dominio</div>
                                            <div className="col-md-2">Clics</div>
                                            <div className="col-md-2">Coste</div>
                                            <div className="col-md-2">Coste medio</div>
                                            <div className="col-md-2">Impresiones</div>
                                            <div className="col-md-2">Tasa de conversión total</div>
                                            <div className="col-md-2">Conversión total</div>
                                            {/*<div className="col-md-4">Fecha</div>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="table-option-list"></div>
                                <div className="table-body">
                                    {
                                        queryList.data &&
                                        queryList.data.map((ele, index) => {

                                            return (
                                                <div key={"table_row_index" + index} className="table-row">
                                                    <div className="row" style={{ flexWrap: "nowrap" }}>
                                                        <div className="col-md-2">{ele.domain}</div>
                                                        <div className="col-md-2">{ele.clicks}</div>
                                                        <div className="col-md-2">{ele.cost}</div>
                                                        <div className="col-md-2">{ele.avg_cost}</div>
                                                        <div className="col-md-2">{ele.impressions}</div>
                                                        <div className="col-md-2">{ele.all_conversaion_rate}</div>
                                                        <div className="col-md-2">{ele.all_conversion ? ele.all_conversion : "-"}</div>
                                                        {/*<div className="col-md-4">{new Date(ele.date).toUTCString()}</div>*/}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            :
                            <NoDataBanner />
                            }
                        </div>
                    </div>
                    <div className='table-container'>
                        <div className='table'>
                            <div className="table-footer">
                                <div className="row pagination">
                                    <div className="col">
                                        <ul >
                                            {
                                                totalRecords > rows &&
                                                <li>
                                                    <button onClick={changeSet.bind(null, 1)} className="btn btn-link">Primera</button>
                                                </li>
                                            }
                                            {
                                                paginationPoints
                                            }
                                            {
                                                totalRecords > rows &&
                                                <li>
                                                    <button onClick={changeSet.bind(null, Math.ceil(totalRecords / rows))} className="btn btn-link">Última</button>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-footer row text-center">
                <div className="col">
                    <small>Pirates Alert - AdBlock</small>
                </div>
            </div>
        </div>
    )
}
export default DashboardReports;