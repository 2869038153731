import React from "react";
import HomeSummary from "../components/HomeSummary";
import DashboardReports from "./DashboardReorts";
import NotificationContext from './../context/NotificationContext';
class Home extends React.Component{
    static contextType = NotificationContext;
    constructor(props){
        super(props);
        this.state={
            
        }
    }
    componentDidMount(){
        this.props.setAppHeading("Inicio");
    }
    render(){
        return(
            <div className="module">
                    <HomeSummary />
                    {/* <RecentActivities /> */}
                    <DashboardReports />
            </div>
        )
    }
}
export default Home;