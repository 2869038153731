import Service from './Service';
class UserService extends Service{
    async getUserByType(req, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_user/getAllUserByType/${req.id}/${req.status}/${req.pageNo}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok)
                this.handleResponseError(response);
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }
}

export default UserService;