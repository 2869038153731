class StorageManager{
    _set(key, val){
        if(typeof(Storage) !== "undefined") {
            localStorage.setItem(key, val);
        }
    }
    _get(key){
        return localStorage.getItem(key)
    }
    _remove(key){
        localStorage.removeItem(key);
    }
}
export default StorageManager;