import React from 'react';

class NumberCounter extends React.Component{
    
    constructor(props){
        super(props);
        //this.animate = this.animate.bind(this);
        //console.log('props ',props)
        //console.log('this.props.number ',this.props.number)
        this.state={
            num:this.props.number//num: 0
        }
    }
    componentDidMount(){
       // this.animate();
    }
    animate(){
        const targetNumber = this.props.number;
        let num = this.state.num; 
        let numberAnimatorInterval = setInterval(()=>{
            if(targetNumber > num)
                num = num + Math.ceil((targetNumber-num)/100);
            else if(targetNumber < num)
                num -= 1;
            else 
                clearInterval(numberAnimatorInterval);
            
            this.setState({
                num: num
            })
        },10);
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.number !== this.props.number){
            //this.animate();
            this.state={
                num:this.props.number//num: 0
            }
        }
    }
    render(){
        const {num} = this.state;
        //console.log('this.state ',this.state)
        //console.log('num ',num)
        const {_prefix, _suffix} = this.props;
        return(
            <span className="number-count">{_prefix + "" + num + "" + _suffix}</span>
        )
    }
}

export default NumberCounter;