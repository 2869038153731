import React from "react";
import UserTable from "../components/tables/UserTable";

class Users extends React.Component{
    componentDidMount(){
        this.props.setAppHeading("Users");
    }
    
    render(){
        return(
            <div className="module">
                <div className='row'>
                    <div className='col'></div>
                    <div className='col text-right'>
                        
                    </div>
                </div>
                <div>
                    <UserTable/>
                </div>
            </div>
        )
    }
}
export default Users;