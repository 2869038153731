import { useContext} from "react";
import AuthContext from "../context/AuthContext";

const UserInfoCard=(props)=>{
    const {auth}=useContext(AuthContext);
    return(
        <div className="user card info container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <div 
                        className="emblem user md">
                    </div>
                </div>
                <div className="col hidable">
                    <div className="name">{auth.userData.accountName}</div>
                    <div className="email">{auth.userData.emailAddress}</div>
                </div>
            </div>
        </div>
    )
}
export default UserInfoCard;