import Service from "./Service";

class SponsorService extends Service{
    async getSponsors(callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_user/getAllSponsors`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.json();
        }).then((data)=>{
            callback(data);
        });
    }

    async getSponsorsByType(pageRequest, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_ad_network/getSponsoredAdAccount/?status=${pageRequest.type}&pageNo=${pageRequest.pageNo}&search=${pageRequest.search}`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.json();
        }).then((data)=>{
            callback(data);
        });
    }

    async updateSponsorDetails(sponsorDetails, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_ad_network/addUpdateSponsor`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(sponsorDetails)
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.json();
        }).then((data)=>{
            callback(data);
        });
    }

    async updateSponsorStatus(sponsorDetails, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_ad_network/updateSponsoredStatus`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(sponsorDetails)
            }
        ).then((response)=>{
            try{
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
            }catch(err){
                console.log('Error ',err)
            }

            return response.json();
        }).then((data)=>{
            callback(data);
        });
    }

}

export default SponsorService;
