import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import AccountService from '../../services/AccountService';
const AccountVerificationForm = props => {
    const { register, handleSubmit, errors } = useForm();
    const [isLoading = false, setLoading] = useState();
    const [response, setResponse] = useState();
    useEffect(()=>{
        
    }, []);
    const submit = (data, event) => {
        event.preventDefault();
        setLoading(true);
        let accountService = new AccountService();
        accountService.verifyAccount(data.verificationCode, (res) => {
            if(res){
                localStorage.removeItem("rToken");
            }
            setResponse(res);
            setLoading(false);
        })
    }
    return (
        <div className="fullscreen d-flex align-items-center justify-content-center">
            {
                response ?
                    <div className="row">
                        {
                            response === "success" ?
                            <div className="col">
                                <div className="alert alert-sm alert-success" role="alert">
                                        <h4 className="alert-heading">¡Enhorabuena!</h4>
                                        <p>Inicia una sesión para acceder a la aplicación.</p>
                                    </div>
                                        <div>
                                            <Link 
                                                className="btn btn-success"
                                                to="/login">
                                                Login
                                            </Link>
                                        </div>
                            </div>
                            :
                            <div className="col">
                                <div className="alert alert-sm alert-danger" role="alert">
                                        <h4 className="alert-heading">Wrong verification code</h4>
                                        <p>Your have entered wrong verification code. </p>
                                    </div>
                                        <div>
                                            <button 
                                                onClick={()=>setResponse()}
                                                className="btn btn-success">
                                                Try again
                                            </button>
                                        </div>
                            </div>
                        }
                    </div>
                    :
                    <div className="form-container">
                        <h5>Verifica tu cuenta</h5>
                        <p>Hemos enviado un código de verificación a tu dirección de correo electrónico registrada. Introduce el código de verificación más abajo.</p>
                        <div className="form">
                            <form onSubmit={handleSubmit(submit)} noValidate autoComplete="off">
                                <div className="form-group">
                                    <label htmlFor="inputFieldVerificationCode">Código de Verificación</label>
                                    <input
                                        type="text"
                                        id="inputFieldVerificationCode"
                                        name="verificationCode"
                                        className="form-control form-control-sm"
                                        aria-describedBy="Enter verification code here"
                                        ref={
                                            register({
                                                required: {
                                                    value: true,
                                                    message: "Please enter validation code"
                                                },
                                                minLength: {
                                                    value: 6,
                                                    message: "Plaese enter validation code correctly"
                                                },
                                                maxLength: {
                                                    value: 6,
                                                    message: "Plaese enter validation code correctly"
                                                },
                                                pattern: {
                                                    value: '^[0-9]$',
                                                    message: "Varification code contains number only, you have entered alphabets or special symbol additionally"
                                                }
                                            })
                                        }
                                    />
                                    {
                                        errors.verificationCode
                                        &&
                                        (
                                            <div className="error-message text-danger">{errors.verificationCode.message}</div>
                                        )
                                    }
                                </div>
                                <div class="row">
                                    <div class="col text-right">
                                        <button className="btn btn-sm btn-success">
                                            {
                                                (isLoading === false || isLoading === undefined) ?
                                                    <span>Verificar</span>
                                                    :
                                                    <span className="spinner-border spinner-border-sm" role="spinner" aria-hidden="true"></span>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }
        </div>
    )
}
export default AccountVerificationForm;