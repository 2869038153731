import React, {useContext} from 'react'
import NotificationContext from '../../context/NotificationContext';
import Notification from './Notification';

const NotificationContainer = (props) => {
    const {notifications} = useContext(NotificationContext);
    
    return(
        <div className="notification-dock">
            {
                notifications.map((ele, index)=>{
                    return (<Notification notification={ele} />)
                })
            }
        </div>
    )
}
export default NotificationContainer;