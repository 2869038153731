import React, { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import SuppportService from '../../services/SupportService';

const QueryInfoPanel=(props)=>{
    const {auth} = useContext(AuthContext);
    const {id, query_title, query_body, generated_on, resolved_on,} = props.query;
    const markResolved=(event)=>{
        event.preventDefault();
        let supportService = new SuppportService();
        const query = {
            id: id,
            isResolve: true
        }
        supportService.markResolved(query, (data)=>{
            props.refresh();
        });
    }
    return( 
        <div className="info-panel query">
            <div className="row">
                <div className="col">
                    <h6>{query_title}</h6>
                    {/* <div>
                        <label>bobwhiteman@yahoo.in</label>
                    </div>
                    <label>{"Bob Whiteman"} <small>(Brand name)</small></label> */}
                </div>
                <div className="col-md-2 text-right">{new Date(generated_on).toLocaleString()}</div>
            </div>
            <hr/>
            <div className="row">
                <div className="col">
                    <p className="text-justify">
                        {query_body}
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                </div>
                <div className="col text-right">
                    {
                        ( auth.userData.accountType === 2 || auth.userData.accountType === 1) &&
                        <ul className="control-option">
                        {
                            resolved_on === null &&
                            (<li>
                                <button onClick={(event)=>markResolved(event)} className="btn btn-sm btn-success">Mark Resolved</button>
                            </li>)
                        }
                        {/* <li>
                            <button className="btn btn-sm btn-default text-success" >
                                <i class="fa fa-star-o" aria-hidden="true"></i>
                            </button>
                        </li>
                        <li>
                            <button className="btn btn-sm btn-default text-success" title="Reply to this query">
                                <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
                            </button>
                        </li>
                        <li>
                            <button className="btn btn-sm btn-default text-danger" title="Delete">
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                        </li> */}
                    </ul>

                    }
                </div>
            </div>
        </div>
    )
}
export default QueryInfoPanel;