import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import NewQueryForm from '../components/forms/NewQueryForm';
import AuthContext from './../context/AuthContext'

import QueryTable from '../components/tables/QueryTable';

class Support extends React.Component{
    static contextType = AuthContext;
    constructor(props){
        super(props);
        this.state={
            moduleHeading: "Consultas anteriores",
            headingAnimationClass: "",
            isMainModule: true
        }
    }
    componentDidMount(){
        this.props.setAppHeading("Soporte");
    }
    done=()=>{
    }
    setModuleHeading=(heading)=>{
        if(heading !== "Consultas anteriores"){
            this.setState({
                isMainModule: false
            });
        }else{
            this.setState({
                isMainModule: true
            });
        }
        let {moduleHeading, headingAnimationClass} = this.state;
        let intervalCounter = 0;
        const animationInterval = setInterval(()=>{
            intervalCounter++;
            switch (intervalCounter) {
                case 1:{
                    headingAnimationClass="fade-out";
                    break;
                }
                case 3:{
                    moduleHeading = heading;                
                    break;
                }
                case 4:{
                    headingAnimationClass="fade-in";
                    break;
                }
                case 5:{
                    clearInterval(animationInterval);
                    break;
                }
                default:
                    break;
            }
            this.setState({
                moduleHeading: moduleHeading,
                headingAnimationClass: headingAnimationClass
            })
        }, 200)
    }
    render(){
        const {headingAnimationClass, moduleHeading, isMainModule} = this.state;
        return(
            <div className="module">
                <div className="module-header">
                    <div className="row">
                        <div className="col">
                            <h5 className={`${headingAnimationClass} animate semi-heading `}>{moduleHeading}</h5>
                        </div>
                        <div className="col animate text-right">
                            {
                                !isMainModule &&
                                <Link to="/adblock/support/">Cancelar</Link>
                            }
                        </div>
                    </div>
                </div>
                <div className="module-body">
                    <Switch>
                        <Route path="/adblock/support/newquery">
                            <NewQueryForm done={this.done()} setModuleHeading={this.setModuleHeading} />
                        </Route>
                        <Route path="/adblock/support">
                            <QueryTable  setModuleHeading={this.setModuleHeading} />
                        </Route>
                    </Switch>
                </div> 
            </div>
        )
    }
}
export default Support;