import Service from './Service';
class BlockListService extends Service{
    
    async getList(resultConfig, success, failure){
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_black_list/getBlockList?pageNo=${resultConfig.pageNumber}&search=${resultConfig.query}`,
            {
                method: "GET",
                mode:"cors",
                headers:{
                    "Content-Type": "application/json",
                    "accept-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            }).then((response)=>{
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
               // alert("Hello! I am an alert box!!");
                    if(!response.ok){
                        this.handleResponseError(response);
                    }else{
                    return response.json();
                    }
            }).then((data)=>{
                success(data);
            })
    }
    async getDownloadableBlockListData(query, callback){
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_black_list/getAllBlockList?search=${query}`,
            {
                method: "GET",
                mode:"cors",
                headers:{
                    "Content-Type": "application/json",
                    "accept-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            }).then((response)=>{
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data)=>{
                callback(data);
            })
    }
    async uploadBlackList(blackList, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}common/uploadBlackList`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body:blackList
            }
        ).then(response=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.text();
            //response.text()
        }).then(data=>callback(data));
    }
}
export default BlockListService;