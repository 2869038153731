import { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from './../../assets/icons/close.svg'
const Notification = props => {
    const [animatedClass, setAnimatedClass] = useState("hide");
    const [isVisible, setVisiblity] = useState(true);
    let animationInterval = null;
    useEffect(() => {
        let counter = 0;
        animationInterval = setInterval(() => {
            switch (counter) {
                case 0: {
                    setAnimatedClass("show");
                    break;
                }
                case 145: {
                    setAnimatedClass("hide");
                    break;
                }
                case 150: {
                    afterUnMount();
                    break;
                }
                default:{
                    break;
                }
            }
            counter++;
        }, 100)
        return (() => {

        })
    }, [])
    const clear = () => {
        setAnimatedClass("hide");
        setTimeout(() => {
            afterUnMount();
        }, 500);
    }
    const afterUnMount = () => {
        setVisiblity(false);
        clearInterval(animationInterval);
    }

    return (!isVisible ? null :
        <div className={`notification ${props.notification.type} ${animatedClass}`} >
            <div className="notification-header">
                <span className="bullet"></span>
                <strong className="notification-title">{props.notification.title}</strong>
                <button onClick={() => clear()} type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                    <CloseIcon className="icon" />
                </button>
            </div>
            <div className="notification-body">
                {props.notification.message}
            </div>
        </div>
    )
}

export default Notification;