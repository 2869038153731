import { useContext, useEffect, useState, } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as AdwordsIcon } from './../../assets/images/adwords.svg';
import { ReactComponent as AdRollIcon } from './../../assets/images/adroll.svg';
import { ReactComponent as MicrosoftIcon } from './../../assets/images/microsoft.svg';
import NetworkService from '../../services/NetworkService';
import NotificationContext from './../../context/NotificationContext';
const AddNetwork = (props) => {
    const [accountList, addAccount] = useState([]);
    const { register, handleSubmit , setValue} = useForm();
    const {notify} = useContext(NotificationContext)
    useEffect(() => {
        props.setModuleHeading('Añadir nueva cuenta de publicidad');

        return (() => {

        });
    }, []);
    // const clearForm = (event) => {
    //     event.preventDefault();
    //     register.networkType.value = '';
    //     register.networkId.value = '';
    // };
    const addAccountDetails = (data, event) => {
        event.preventDefault();
        setValue('networkType', '');
        setValue('networkId', '');
        addAccount([...accountList, data]);
    }
    const submit=(event)=>{
        event.preventDefault();
        if(accountList.length > 0){
            const accounts = [];
            accountList.forEach(ele=>{
                accounts.push([ele.networkId, ele.networkType]);
            });
            let networkService = new NetworkService();
            networkService.addNetworks(accounts,(data)=>{
                console.log(data);
                if(data==='success'){
                    notify({
                        type: 'success',
                        title: 'Success',
                        message: `New network added`
                    })
                    addAccount([])
                }
            });
        }else{
            notify({
                type: 'success',
                title: 'Success',
                message: 'Add atleast one account.'
            })
        }
    }
    const removeItem=(index)=>{
        let tempArray = accountList;
        tempArray.splice(index, 1);
        addAccount([...tempArray]);
    }
    return (
        <div className='panel'>
            <div className='row'>
                <div className='col-md-3'>
                    {/* <input type='text' className='form-control-sm form-control' /> */}
                </div>
                <div className='col text-right' >

                </div>
            </div>
            <div className='row'>
                <div className='col-md-5'>
                    <form onSubmit={handleSubmit(addAccountDetails)} noValidate autoComplete='off'>
                        <div className='row'>
                            <div className='col'>
                                <h6>Detalles de nueva cuenta de publicidad</h6>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor='inputFieldAccountType'>Tipo de cuenta de publicidad</label>
                                    <select
                                        id='inputFieldAccountType'
                                        name='networkType'
                                        ref={register({
                                            required: {
                                                value: true,
                                                message: 'Select network type'
                                            }
                                        })}
                                        className='form-control form-control-sm'>
                                        <option>--seleccionar--</option>
                                        <option value='1' selected>AdWords</option>
                                        {/* <option value='2'>AdRoll</option> */}
                                        {/* <option value='3'>Microsoft Ads</option> */}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor='inputFieldAccountId'>ID de cuenta de publicidad</label>
                                    <input
                                        type='text'
                                        id='inputFieldAccountId'
                                        name='networkId'
                                        ref={register({
                                            required: {
                                                value: true,
                                                message: 'Enter network id'
                                            }
                                        })}
                                        className='form-control form-control-sm'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <button type='submit' className='btn btn-sm btn-success'>Añadir a lista</button>
                            </div>
                            <div className='col text-right'>
                                <button type='reset' className='btn btn-sm btn-link'>Borrar</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='col'></div>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col'>
                            <h6>Cuenta añadida</h6>
                        </div>
                    </div>
                    <div className='container'>
                        {
                            accountList.length > 0 ?
                                <div className='network-bucket filled'>
                                    {
                                        accountList.map((ele, index) => {
                                            return <div key={'network_bucket_tile_'+index} className='row network-bucket-row justify-content-start align-items-center'>
                                                <div className='col-md-2 compact-link'>
                                                    {
                                                        ele.networkType === '1' ?
                                                            <span className='emblem sm network-thumb'><AdwordsIcon /></span>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        ele.networkType === '2' ?
                                                            <span className='emblem sm network-thumb'><AdRollIcon /></span>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        ele.networkType === '3' ?
                                                            <span className='emblem sm network-thumb'><MicrosoftIcon /></span>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className='col'>
                                                    <label>{ele.networkId}</label>
                                                </div>
                                                <div className='col text-right'>
                                                    {/* <button className='btn btn-sm btn-default text-success'>
                                                        <i className='fa fa-pencil' aria-hidden='true'></i>
                                                    </button> */}
                                                    <button onClick={()=>removeItem(index)} className='btn btn-sm btn-default text-danger'>
                                                        <i className='fa fa-times' aria-hidden='true'></i>
                                                    </button>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                :
                                <div className='network-bucket empty'>
                                    <h5 className='semi-heading'>No se añadió ninguna cuenta de publicidad</h5>
                                </div>
                        }
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <button onClick={(event)=>submit(event)} disabled={accountList.length > 0 ? null : true} className='btn btn-sm btn-success'>Añadir cuentas de publicidad</button>
                        </div>
                        <div className='col text-right'>
                            <button
                                disabled={accountList.length > 0 ? null : true}
                                className='btn btn-sm btn-link'
                                onClick={()=>addAccount([])}
                            >Borrar lista</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default AddNetwork;
