const { Component } = require("react");

class Invoice extends Component{
    constructor(props){
        super(props);
        this.state={
            
        }

    }
    componentDidMount(){
        this.props.setAppHeading("Invoice");
    }
    render(){
        return(
            <div>invoic</div>
        )
    }
}
export default Invoice;