import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import AccountService from '../../services/AccountService';
const AccountRecovery = () => {
    const { register, handleSubmit, errors } = useForm();
    const [ recoverStatus, setStatus ] = useState({success: undefined, message: undefined});
    const [ isSubmitted, setSubmission ] = useState(false);
    const [isLoading, setLoading] = useState(false);
    
    const submit = (data, event) => {
        let accountService = new AccountService();
        let req = {
            email_address: data.emailAddress
        }
        setLoading(true);
        accountService.recoverAccount(req, (resp)=>{
            setLoading(false)
            setSubmission(true);
            setStatus(resp);
        })
    }
    const reset=(event)=>{
        setStatus({success: undefined, message: undefined});
        setSubmission(false);
    }    
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col highlight-background">
                    <div className="fullscreen d-flex align-items-center justify-content-center">
                    </div>
                </div>
                <div className="col">
                    <div className="fullscreen d-flex align-items-center justify-content-center">
                        {
                            !isSubmitted ?
                            <div className="form-container">
                                <h5>Recuperar cuenta</h5>
                                {/*<p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</p>*/}
                                <div className="form">
                                    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
                                        <div className="form-group">
                                            <label htmlFor="inputFieldRecoveryEmailAddress">Introduce la dirección de correo electrónico de recuperación</label>
                                            <input
                                                disabled={isLoading ? true : null}
                                                type="email"
                                                id="inputFieldRecoveryEmailAddress"
                                                name="emailAddress"
                                                className="form-control"
                                                ref={
                                                    register({
                                                        required: {
                                                            value: true,
                                                            message: "Por favor ingrese la dirección de correo electrónico"
                                                        },
                                                        pattern: {
                                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: "Esta no parece una dirección de correo electrónico válida. Vuelve a intentarlo"
                                                        }
                                                    })
                                                }
                                            />
                                            {
                                                errors.emailAddress
                                                &&
                                                (
                                                    <div className="error-message text-danger">{errors.emailAddress.message}</div>
                                                )
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                    <Link
                                                        disabled={isLoading ? true : null}
                                                        className="btn btn-sm btn-link"
                                                    to="/login">Inicio de sesión</Link>
                                            </div>
                                            <div className="col text-right">
                                                <button 
                                                    disabled={isLoading ? true : null}
                                                    type="submit" 
                                                    className="btn btn-sm btn-success">
                                                    {
                                                        isLoading?
                                                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                                        :
                                                        "Recuperar cuenta"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            :
                            <div>
                                
                                {
                                    recoverStatus.success ? 
                                    <div className="alert alert-sm alert-success" role="alert">
                                        <h4 className="alert-heading">Well done!</h4>
                                        <p>{recoverStatus.message}</p>
                                    </div>
                                    :
                                    <>
                                        <div className="alert alert-sm alert-danger" role="alert">
                                            <h4 className="alert-heading">Something went wrong</h4>
                                            <p>{recoverStatus.message}</p>
                                        </div>
                                        <div>
                                                <button onClick={(event)=>reset(event)} className="btn btn-sm btn-success">
                                                    Try login again
                                                </button>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AccountRecovery;