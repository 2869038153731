import { useContext, useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import LoginService from "../../services/LoginService";
import AuthContext  from '../../context/AuthContext';


const LoginForm =()=> {
    const { setAuth} = useContext(AuthContext);
    const [response, setResponse] = useState();
    const { register, handleSubmit, errors } = useForm();
    const [isLoading, setLoading] = useState();
    const [activityMessage, setActivityMessage]=useState("");
    useEffect(()=>{
        let token = localStorage.getItem("token");
        if(token!=null){
            let loginService = new LoginService();
            loginService.getUserDetails((data)=>{
                if(data.msg !== "access to the requested resource is forbidden"){
                    localStorage.setItem("user", JSON.stringify(data));
                    setAuth({
                        isLoggedIn: true,
                        userData: {
                            accountName: data.account_name,
                            emailAddress: data.email_address,
                            accountType: data.account_type
                        },
                    })
                }else{
                    localStorage.removeItem("token");
                    setAuth({
                        isLoggedIn: false,
                        userData: undefined
                    })
                }
            })
        }
    }, []);
    const submit = (data, event) => {
        event.preventDefault();
        setLoading(true);
        const userCred = {
            email_address : "" + data.username,
            password : "" + data.password
        };
        const loginService = new LoginService();
        setLoading(true);
        setActivityMessage("");
        loginService.authenticate(userCred,(data)=>{
            if(data.success)
            {
                localStorage.setItem("token", data.token);
                loginService.getUserDetails((data)=>{
                    if(data.msg!== "" && data.id > 0){
                        if(data.account_name === "NA"){
                            return <Redirect to="/registration/id=3" />
                        }else{

                        }
                        if(data.account_type === 1 || data.account_type === 2){
                            if( data.is_active === 1 && data.is_deleted === 0 && data.is_varified === 1 ){
                                setAuth({
                                    isLoggedIn: true, 
                                    userData: {
                                        accountName: data.account_name,
                                        emailAddress: data.email_address,
                                        accountType: data.account_type
                                    }
                                })
                            }else{
                                localStorage.removeItem("token");
                                if(data.is_deleted === 1)
                                    setResponse({
                                        isSuccess: false,
                                        errorCode: 0
                                    })
                                else if(data.is_active === 0)
                                    setResponse({
                                        isSuccess: false,
                                        errorCode: 1
                                    })
                                else if(data.is_varified === 0)
                                    setResponse({
                                        isSuccess: false,
                                        errorCode: 2
                                    })
                            }
                        }else{
                            setAuth({
                                isLoggedIn: true, 
                                userData: {
                                    accountName: data.account_name,
                                    emailAddress: data.email_address,
                                    accountType: data.account_type
                                }
                            })
                        }
                    }
                })
                // history.push("/waitingroom")
            }else{
                setActivityMessage(data.message);
                setLoading(false);
            }
        }) 
    }
    const dateTime = new Date().getHours();
    let welcomeMessage = "";
    if (dateTime < 12)
        welcomeMessage = "Bienvenido a";
    else if (dateTime >= 12 && dateTime <= 5)
        welcomeMessage = "Bienvenido a";
    else if (dateTime > 6 && dateTime <= 24)
        welcomeMessage = "Bienvenido a";
    else
        welcomeMessage = "Bienvenido a";
    return (
        <div className="fullscreen d-flex align-items-center justify-content-center">
            {
                (response && response.isSuccess === false) ?
                <>
                {
                    response.errorCode === 0 && 
                    (
                        <div className="alert alert-sm alert-success" role="alert">
                            <h4 className="alert-heading">Account Deteleted!</h4>
                            <p>Your account has been deleted. for more information contact admin.</p>
                        </div>
                    )
                }
                {
                (response && response.errorCode === 1) && 
                    (
                        <div className="alert alert-sm alert-success" role="alert">
                            <h4 className="alert-heading">Account Deactivated!</h4>
                            <p>Your account has been deactivated for some reason. For more information or to gain account access contact admin.</p>
                        </div>
                    )
                }
                {
                (response && response.errorCode === 2) && 
                    (
                        <div className="">
                        <div className="alert alert-sm alert-success" role="alert">
                            <h4 className="alert-heading">Verification pending!</h4>
                            <p>Your account is not yet verified. Click below to verify.</p>
                        </div>
                        <Link className="btn btn-success" to="/registration/verification">Verify</Link>
                        </div>
                    )
                }
                </>
                :
                <div className="form-container">
                <h3>{welcomeMessage} PiratesAlert</h3>
                <p>Debes introducir los datos de acceso más abajo para entrar en el sistema.</p>
                <div className="form">
                    <form  onSubmit={handleSubmit(submit)} noValidate autoComplete="off">
                        <div className="form-group">
                            <label htmlFor="inputFieldUsername">Correo electrónico</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="username"
                                disabled={isLoading?true:null}
                                aria-describedby="Enter username"
                                ref={
                                    register({
                                        required: {
                                            value: true,
                                            message: "Please enter username."
                                        }
                                    })
                                }
                            />
                            {
                                errors.username
                                &&
                                (
                                    <div className="text-danger">
                                        {errors.username.message}
                                    </div>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFieldUsername">Contraseña</label>
                            <input
                                type="password"
                                className="form-control form-control-sm"
                                name="password"
                                disabled={isLoading?true:null}
                                aria-describedby="Enter username"
                                ref={
                                    register({
                                        required: {
                                            value: true,
                                            message: "Please enter password."
                                        }
                                    })
                                }
                            />
                            {
                                errors.password
                                &&
                                (
                                    <div className="text-danger">
                                        {errors.password.message}
                                    </div>
                                )
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                {
                                    activityMessage
                                    && 
                                    (
                                        <div className="alert alert-danger alert-dismissible fade show">
                                            <strong>Failed!</strong> {activityMessage}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <button type="submit" disabled={isLoading?true:null} className="btn btn-sm btn-success">
                                    {
                                        isLoading ?
                                        <i className="fa-spin fa fa-spinner" aria-hidden="true"></i>
                                        :
                                        "Inicio de sesión"
                                    }
                                </button>
                            </div>
                            <div className="col text-right">
                                <Link disabled={isLoading?true:null} className="btn btn-sm btn-link compact-link" to="/registration?stage=0">Crear una cuenta</Link>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <h6>¿Necesitas ayuda?</h6>
                            <div>
                                <Link disabled={isLoading?true:null} className="btn btn-sm btn-link compact-link" to="/accountrecovery">¿Has olvidado la contraseña?</Link>
                            </div>
                        </div>
                        
                        <div className='row' style={{marginTop: '25%'}}>
                            <div className='col'>
                                <small><a target='_blank' href='privacy_policy.html'>Política de privacidad</a></small>
                            </div>
                            <div className='col text-center'>
                                <small><a target='_blank' href='/terms_and_condition.html'>Términos y Condiciones</a></small>
                            </div>
                            <div className='col text-right'>
                                <small><a target='_blank' href='/Disclamer.html'>Descargo de responsabilidad</a></small>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            }
        </div>
    )
}

export default LoginForm;