import { useState } from "react";
import { Link } from "react-router-dom";

const Failed=(props)=>{
    return(
        <div className="fullscreen flex-column d-flex align-items-center justify-content-center">
            <div className="alert alert-danger alert-dismissible fade show">
                <strong>Failed!</strong> This action is not allowed.
            </div>
            <Link to='/' className="btn btn-success">Go home</Link>
        </div>
    )
}

export default Failed;