import DeadTableRow from "../DeadTableRow";
import {useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlockListService from "../../services/BlockListService";
import MiniLoader from "../other/MiniLoader";
import exportFromJSON from "export-from-json";
import AuthContext from "../../context/AuthContext";
import PaginationPoints from './../../components/other/PaginationPoint'
import NotificationContext from "../../context/NotificationContext";
const BlockListTable = (props) => {
    const {auth} = useContext(AuthContext);
    const [blockList, setBlockList] = useState(
        {
            totalRecords:0, pageNo:0, rows:0, data:[]
        }
    )
    const {register, handleSubmit, errors, setValue} = useForm(0);
    const [search, setSearch] = useState('');
    const [isDownloading, setDownloading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const {notify} = useContext(NotificationContext);
    useEffect(() => {
        setLoading(true);
        props.setModuleHeading("Bloqueo reciente")
        let blockListService = new BlockListService();
        let requestConfig = {
            pageNumber: 1,
            query: search
        }
        blockListService.getList(requestConfig, (data) => {
            setBlockList(data);
            setLoading(false);
        });
    }, []);
    const { totalRecords, pageNo, rows, data } = blockList;
    const deadRows = [];

    
    if (data.length < rows) {
        for (let index = data.length; index < 10; index++) {
            deadRows.push(<DeadTableRow key={"dead_row_blocklist_" + index} index={index + 1} />);
        }
    }
    const submit=(data, event)=>{
        event.preventDefault();
        setLoading(true);
        let requestConfig = {
            pageNumber: 1,
            query: ""+ data.query
        }
        let blockListService = new BlockListService();
        blockListService.getList(
            requestConfig,
            (data)=>{
                setLoading(false);
                setBlockList(data);
            }
        );

    }
    const downloadBlockList=(event)=>{
        event.preventDefault();
        let blockListService = new BlockListService();
        setDownloading(true);
        blockListService.getDownloadableBlockListData(search,(data)=>{
            const fileName = 'Ad Exclusion List';
            const exportType = 'csv';
            exportFromJSON({data, fileName, exportType});
            setDownloading(false);
            notify({
                type: 'success',
                title: "¡Hecho!",
                message: `Archivo descargado correctamente`
            })
        });
    }
    const changeSet = (setNumber) => {
        if(pageNo !== setNumber){
            let blockListService = new BlockListService();
            let requestConfig = {
                pageNumber: setNumber,
                query: search
            }
            blockListService.getList(requestConfig, (data) => {
                setBlockList(data);
                setLoading(false);
            });
        }
    }
    const paginationPoints = [];
    if(totalRecords > rows){
        for (let index = Math.max(1, pageNo-5) ; index <= Math.min(pageNo+5, Math.ceil(totalRecords/rows)); index++) {
            let isActive = (pageNo === index) ? "active" : ""
            paginationPoints.push(<PaginationPoints onClick={changeSet.bind(null, index)} isActive={"" + isActive} setNumber={index} />);
        }
    }
    const clearSearch=()=>{
        setSearch("");
        let requestConfig = {
            pageNumber: 1,
            query: ""
        }
        let blockListService = new BlockListService();
        blockListService.getList(
            requestConfig,
            (data)=>{
                setLoading(false);  
                setBlockList(data);
            }
        );
    }
    return (
        <div className="table-container">
            <div className="row">
                <div className="col-md-6">
                
                    <form onSubmit={handleSubmit(submit)}>
                        <div className="row">
                            <div className="col">
                                <input 
                                    className="form-control form-control-sm" 
                                    type="text"
                                    name="query" 
                                    placeholder="Buscar aquí                                    " 
                                    onChange={(e)=>setSearch(e.target.value)}
                                    ref={
                                        register({
                                            required:{
                                                value:true,
                                                message:"Introduce alguna palabra clave.                                                "
                                            }
                                        })
                                    }
                                />
                            </div>
                            <div className="col">
                                <button type="submit" className="btn btn-success btn-sm">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                </button>
                                <button onClick={()=>clearSearch()} type="reset" className="btn btn-light btn-sm">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        {
                            errors.query 
                            &&
                            <small className="text-danger">
                                {errors.query.message}
                            </small>
                        }

                    </form>
                </div>
                <div className="col text-right">
                    <button onClick={(event)=>downloadBlockList(event)} className="btn btn-success btn-sm">
                        {
                            isDownloading?
                            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                            :
                            "Descargar lista de bloqueo"
                        }
                    </button>
                </div>
                {
                    (auth.userData.accountType === 1 || auth.userData.accountType === 2) &&
                    <div className="col-md-3 text-right">
                        <Link to="/adblock/blocklist/upload" className="btn btn-sm btn-success">
                            Cargar nueva lista de bloqueo
                        </Link>
                    </div> 
                }
            </div>
            {
                isLoading ?
                    <MiniLoader />
                    :
                    <div className="table">
                        <div className="table-header">
                            <div className="row">
                                <div className="col-md-1">N.º ref.</div>
                                <div className="col-md-6">Posición</div>
                                <div className="col-md-3">Tipo</div>
                                {/* <div className="col-md-2">Status</div> */}
                            </div>
                        </div>
                        <div className="table-option-list"></div>
                        <div className="table-body">
                            {
                                data.map((ele, index) => {
                                    let statusClass = "badge-danger";
                                    if (ele.status === "added")
                                        statusClass = "badge-success";
                                    else if (ele.status === "pending")
                                        statusClass = "badge-warning";

                                    return (
                                        <div key={"table_row_index" + index} className="table-row foldable">
                                            <div 
                                                // onMouseUp={() => releaseMenu()} 
                                                // onMouseDown={(e) => holdMenu(e)} 
                                                key={"blocklist_" + index} className="row">
                                                <div className="col-md-1">
                                                    <label>
                                                        {ele.id}
                                                        {index+1}
                                                    </label>
                                                </div>
                                                <div className="col-md-6 placement-value">
                                                    {/* put ele type icon here */}
                                                    <label title={ele.domain}>
                                                        {
                                                            ele.domain.length < 41 ? ele.domain : ele.domain.substr(0, 40) + "..."
                                                        }
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <label>{ele.type}</label>
                                                </div>
                                                {/* <div className="col-md-2">
                                                    <label><small className={`badge ${statusClass}`}>not updated</small></label>
                                                    <label><small className={`badge ${statusClass}`}>{ele.status.toUpperCase()}</small></label>
                                                </div> */}

                                            </div>
                                            {/* <div className="foldable-container">
                                                <div className="folded-content">
                                                    <PlacementInfoPanel placement={ele} />
                                                </div>
                                            </div> */}
                                        </div>
                                    )
                                })
                            }
                            {/* Adding dead rows */}
                            {
                                deadRows
                            }
                        </div>
                        <div className="table-footer">
                        <div className="row pagination">
                            <div className="col">
                                <ul >
                                    {
                                        totalRecords > rows &&
                                        <li>
                                            <button onClick={changeSet.bind(null, 1)} className="btn btn-link">primera</button>
                                        </li>
                                    }
                                    {
                                        paginationPoints
                                    }
                                    {
                                        totalRecords > rows &&
                                        <li>
                                            <button onClick={changeSet.bind(null, Math.ceil(totalRecords/rows))} className="btn btn-link">última</button>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>

            }
        </div>
    )
}
export default BlockListTable;