import { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import SponsorPanel from "../components/panels/SponsorPanel";
import AddNetworks from "../components/panels/AddNetwork";
import NetworkTable from "../components/tables/NetworkTable";
import NetworkService from "../services/NetworkService";

class AdNetworks extends Component{
    constructor(props){
        super(props);
        this.state={
            moduleHeading: "Cuentas de publicidad existentes",
            headingAnimationClass: "",
            isMainModule: true,
        }
        this.networkService = new NetworkService();
    }
    componentDidMount(){
        this.props.setAppHeading("Cuentas");
    }
    setModuleHeading=(heading)=>{
        if(heading !== "Cuentas de publicidad existentes"){
            this.setState({
                isMainModule: false
            });
        }else{
            this.setState({
                isMainModule: true
            });
        }
        let {moduleHeading, headingAnimationClass} = this.state;
        let intervalCounter = 0;
        const animationInterval = setInterval(()=>{
            intervalCounter++;
            switch (intervalCounter) {
                case 1:{
                    headingAnimationClass="fade-out";
                    break;
                }
                case 3:{
                    moduleHeading = heading;
                    break;
                }
                case 4:{
                    headingAnimationClass="fade-in";
                    break;
                }
                case 5:{
                    clearInterval(animationInterval);
                    break;
                }
                default:
                    break;
            }
            this.setState({
                moduleHeading: moduleHeading,
                headingAnimationClass: headingAnimationClass
            })
        }, 200)
    }
    render(){
        const{moduleHeading, headingAnimationClass, isMainModule} = this.state;
        return(
            <div className="module">
                <div className="module-header">
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col">
                            <h5 className={`${headingAnimationClass} animate semi-heading `}>{moduleHeading}</h5>
                        </div>
                        <div className="col text-right">
                            {
                                !isMainModule &&
                                    <Link to="/adblock/networks/" >Atrás</Link>
                            }
                        </div>
                    </div>
                </div>
                <div className="module-body">
                    <Switch>
                        <Route path="/adblock/networks/getsponsor">
                            <SponsorPanel setModuleHeading={this.setModuleHeading}/>
                        </Route>
                        <Route path="/adblock/networks/add">
                            <AddNetworks setModuleHeading={this.setModuleHeading}/>
                        </Route>
                        <Route path="/adblock/networks">
                            <NetworkTable setModuleHeading={this.setModuleHeading}/>
                        </Route>
                    </Switch>
                </div>
            </div>
        )
    }
}
export default AdNetworks;
