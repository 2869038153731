import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import NetworkService from "../../services/NetworkService";
import MiniLoader from "../other/MiniLoader";
import NetworkTiles from "../tiles/NetworkTiles";
import { ReactComponent as AdwordsIcon } from "./../../assets/images/adwords.svg";
import { ReactComponent as AdRollIcon } from "./../../assets/images/adroll.svg";
import { ReactComponent as MicrosoftIcon } from "./../../assets/images/microsoft.svg";
import SponsorService from "../../services/SponsorService";
import SponsorAdminPanel from "../panels/SponsorAdminPanel";

const NetworkTable = (props) => {
    const { auth } = useContext(AuthContext)
    const [networkList, setNetworkList] = useState([]);
    const [allSponsor, setAllSponsor] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [activeId, setActiveId] = useState(0);
    const [pageLoad, setPageLoad] = useState(1);
    useEffect(() => {
        props.setModuleHeading("Cuentas de publicidad existentes");
        getNetworkList();
    }, []);
    const getNetworkList = () => {
        let networkService = new NetworkService();
        setLoading(true);
        networkService.getNetworkList((data) => {
            setLoading(false);
            setNetworkList(data);
        },
            (error) => {
                setLoading(false);
            }
        )
    }
    const expandDetails = (id) => {
        setActiveId(id);
        let sponsorService = new SponsorService();
        sponsorService.getSponsors((data) => {
            setAllSponsor(data);
        });
    }
    const manageSponsor = (ele, state) => {
        let sponsorDetails = [
            {
                id: activeId,
                paid_details: [
                    {
                        id: ele.id,
                        status: state === 1 ? "Pending" : "Not Active",
                        sponsor: ele.account_name
                    }
                ]
            }
        ]
        let sponsorService = new SponsorService();
        sponsorService.updateSponsorDetails(sponsorDetails, (data) => {
            getNetworkList();
        })
    }
    const handleSponsorship = (state) => {

    }
    const activeNetwork = networkList.find(network => network.id === activeId) ? networkList.find(network => network.id === activeId) : undefined;
    let accountNetworkStatus = '';
    if (activeNetwork) {
        switch (activeNetwork.linked_status.toLowerCase()) {
            case 'l': {
                accountNetworkStatus = 'Linked';
                break;
            }
            case 'a':
            case 'o': {
                accountNetworkStatus = 'Yet to Send';
                break;
            }
            case 'p': {
                accountNetworkStatus = 'Waiting for Permission';
                break;
            }
            case 'ru':
            case 'ou': {
                accountNetworkStatus = 'Yet to Send';
                break;
            }
            case 'u': {
                accountNetworkStatus = 'Unlink';
                break;
            }
            default: {
                break;
            }
        }
    }
    let loadedNetworks = [];
    if(networkList.length > 0){
        for(let i=0; i < Math.min(networkList.length, pageLoad * 10); i++){
            if (networkList[i].is_deleted === 0)
                loadedNetworks.push(<NetworkTiles
                    expandDetails={(id) => expandDetails(id)}
                    refresh={getNetworkList}
                    network={networkList[i]}
                    key={"network_tiles_" + i} />);
        }
    }
    return (
        <>
            {
                (auth.userData.accountType === 1 || auth.userData.accountType === 2) ?
                    <SponsorAdminPanel />
                    :
                    <div className="table-container">
                        <div className="row">
                            <div className="col-md-3">
                                {/* <input className="form-control form-control-sm" type="text" placeholder="Search here" /> */}
                            </div>
                            {
                                (auth.userData.accountType === 3 || auth.userData.accountType === 4) &&
                                <div className="col text-right">
                                    <ul className="module-options">
                                        <li>
                                            <Link to="/adblock/networks/add" className="btn btn-sm btn-success">Añadir cuenta de publicidad</Link>
                                        </li>
                                        {/* <li>
                                <Link to="/adblock/networks/getsponsor" className="btn btn-sm btn-success">Get Sponsor</Link>
                            </li> */}
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className="table no-padding">
                            {
                                activeId > 0 ?
                                    <div className="network-details">
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="semi-heading">Details</h5>
                                            </div>
                                            <div className="col text-right">
                                                <button className="btn btn-sm btn-light" onClick={() => setActiveId(0)}>Close</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col text-center">
                                                <div className="dnetemb">
                                                    {
                                                        activeNetwork.type.toLowerCase() === "adwords" &&
                                                        <span><AdwordsIcon /></span>
                                                    }
                                                    {
                                                        activeNetwork.type.toLowerCase() === "adroll" &&
                                                        <span><AdRollIcon /></span>
                                                    }
                                                    {
                                                        activeNetwork.type.toLowerCase() === "microsoft" &&
                                                        <span><MicrosoftIcon /></span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="details text-center">
                                            <div className="row">
                                                <div className="col">
                                                    <h5><b>{activeNetwork.type}</b> {activeNetwork.ad_account_id}</h5>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <div className="col text-right">
                                                    <b>Account Linked On</b>
                                                </div>
                                                <div className="col text-left">
                                                    {
                                                        new Date(activeNetwork.linked_on).toLocaleString()
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col text-right">
                                                    <b>Linked Status</b>
                                                </div>
                                                <div className="col text-left">
                                                    {
                                                        accountNetworkStatus
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5>Recent Sponsor Details</h5>
                                                    </div>
                                                </div>
                                                {

                                                    (activeNetwork.paid_details && activeNetwork.paid_details.length > 0) ?
                                                        <>
                                                            {
                                                                activeNetwork.paid_details.map((ele, index) => {
                                                                    return (<div className="row sponsorship-row">
                                                                        <div className="col">
                                                                            {ele.sponsor}
                                                                        </div>
                                                                        <div className="col">
                                                                            {
                                                                                (auth.userData.accountType === 1 || auth.userData.accountType === 2) ?
                                                                                    <>
                                                                                        {
                                                                                            ele.status.toLowerCase() === "active" ?
                                                                                                <button onClick={() => handleSponsorship(0)} className="btn btn-sm btn-danger">Remove Sponsorship</button>
                                                                                                :
                                                                                                <button onClick={() => handleSponsorship(1)} className="btn btn-sm btn-success">Accept Sponsorship</button>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>{ele.status}</>
                                                                            }
                                                                        </div>
                                                                    </div>)
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <div className='text-info'>Not sponsored by anyone</div>
                                                }
                                                {
                                                    (auth.userData.accountType === 3 || auth.userData.accountType === 4) &&
                                                    <div>
                                                        <br></br>
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5>All sponsors</h5>
                                                            </div>
                                                        </div>
                                                        {

                                                            (allSponsor && allSponsor.length > 0) ?
                                                                <>
                                                                    {
                                                                        allSponsor.map((ele, index) => {
                                                                            return (<div className="row sponsorship-row">
                                                                                <div className="col">
                                                                                    {ele.account_name}
                                                                                </div>
                                                                                <div className="col">
                                                                                    {
                                                                                        activeNetwork.paid_details.find((net) => net.id === ele.id) !== undefined ?
                                                                                            <>

                                                                                                {
                                                                                                    activeNetwork.paid_details.find((net) => net.id === ele.id).status.toLowerCase() === "pending" ?
                                                                                                        <button onClick={() => manageSponsor(ele, 0)} className="btn btn-sm btn-warning">Cancel Request</button>
                                                                                                        :
                                                                                                        <button onClick={() => manageSponsor(ele, 1)} className="btn btn-sm btn-success">Request Sponsorship</button>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            <button onClick={() => manageSponsor(ele, 1)} className="btn btn-sm btn-success">Request Sponsorship</button>
                                                                                    }

                                                                                </div>
                                                                            </div>)
                                                                        })
                                                                    }
                                                                </>
                                                                :
                                                                <h5>No sponsor found</h5>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <>
                                        {
                                            isLoading === true ?
                                                <MiniLoader message={"Fetching all networks"} />
                                                :
                                                <div className="table-body d-flex justify-content-between flex-wrap">
                                                    {
                                                        loadedNetworks
                                                    }
                                                </div>
                                        }
                                        {
                                            networkList.length > 10 && pageLoad <= Math.floor(networkList.length/10) ? 
                                            <div className='text-center'>
                                                <button 
                                                    onClick={()=>setPageLoad(pageLoad + 1)}
                                                    className='btn btn-light'
                                                >Load More</button>
                                            </div>
                                            :
                                            null
                                        }
                                    </>
                            }
                        </div>
                    </div>
            }
        </>
    )
}
export default NetworkTable;
