// const App=(props)=>props.children;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthContext from "./context/AuthContext";
import NotificationContext from "./context/NotificationContext";
import Routes from "./Routes";
import LoginService from "./services/LoginService";
import NotificationContainer from './components/other/NotificationContainer';
const App = props => {
    const [auth, setAuth] = useState({
        isLoggedIn: null,
        userData: undefined,
    });
    const [notifications, setNotifications] = useState([]);
    const notify=(notification)=>{
        notification.id = notifications.length;
        setNotifications([...notifications, notification]);
    }
    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token != null) {
            let loginService = new LoginService();
            loginService.getUserDetails((data) => {
                console.log('checking the token msg',data)
                if (data.msg === 'access to the requested resource is forbidden' ||data.msg === 'Token expire' ) {
                    localStorage.removeItem("token");
                    setAuth({
                        isLoggedIn: false,
                        userData: undefined
                    })
                }
                else {

                    setAuth({
                        isLoggedIn: true,
                        userData: {
                            accountName: data.account_name,
                            emailAddress: data.email_address,
                            accountType: data.account_type
                        },
                    })
                }
            });
        } else {
            localStorage.clear();
            setAuth({
                isLoggedIn: false,
                userData: undefined
            })
        }
    }, []);
    return (
        <React.Fragment>
            {
                (auth.isLoggedIn === true || auth.isLoggedIn === false) ?
                    <AuthContext.Provider value={{ auth, setAuth }}>
                        <NotificationContext.Provider value={{notifications, notify}}>
                        <div>
                            <NotificationContainer/>
                            <Router>
                                <Routes />
                            </Router>
                        </div>
                        </NotificationContext.Provider>
                    </AuthContext.Provider>
                    :
                    <div className='fullscreen d-flex justify-content-center align-items-center'>
                        <span className='app-loader'>
                        </span>
                        <label className='text-secondary'>Loading</label>
                    </div>
            }
        </React.Fragment>
    )
}
export default App;