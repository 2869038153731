import { useEffect, useState } from "react";
import SponsorService from './../../services/SponsorService';

const SponsorPanel=(props)=>{
    const [sponsors, setSponsor] = useState([]);
    useEffect(()=>{
        props.setModuleHeading("Get Sponsor");
        let sponsorService = new SponsorService();
        sponsorService.getSponsors((data)=>{
            setSponsor(data);
        });
        return(()=>{

        });
    }, []);
    const requestSponsor=(id)=>{
        // let sponsorRequest = 
    }
    return(
        <div className="panel">
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-md-3">
                    {/* <input type="text" placeholder="Search sponsor here" className="form-control-sm form-control" /> */}
                </div>
                <div className="col text-right" >
                    
                </div>
            </div>
            <div>
                {
                    sponsors.map((ele, index)=>{
                        return (<div key={"sponsor_" + index} className="row d-flex  align-items-center">
                                
                                    <div className="col-1"><span className="emblem md network-thumb user"></span></div>
                                    <div className="col"><label>{ele.account_name}</label></div>
                                    <div className="col">
                                        <button
                                            onClick={(event)=>requestSponsor(ele)} 
                                            className="btn btm-sm btn-success">Request Sponsorship</button>
                                    </div>
                                
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default SponsorPanel;