import Service from "./Service";

class NetworkService extends Service{
    async getNetworkList(success, failure){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_ad_network/getByUserId`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "accept-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            }
            ).then((response)=>{
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok)
                    this.handleResponseError(response);
                return response.json();
            }).then((data)=>{
                success(data)
            })
    }

    async addNetworks(data, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_ad_network/addAdAccount`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type" : "application/json",
                    "Accept-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        ).then((response) =>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.text();
        }).then((data)=>{
            callback(data);
        })
    }

    async deleteNetwork(networkId, success, failure){
        // const access_point = process.env.ACCESS_POINT;
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_ad_network/deleteAdAccount/${networkId}`,
            {
                method:"PUT",
                mode:"cors",
                headers:{
                    "content-type":"application/json",
                    "Authorization":`Bearer ${localStorage.getItem("token")}`
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok)
                this.handleResponseError(response);
            return response.json();
        }).then((data)=>{
            success(data);
        })
    }

    async getNetworks(callback){
        // const access_point = process.env.ACCESS_POINT;
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_ad_network/getAdDropDown`,
            {
                method:"get",
                mode:"cors",
                headers:{
                    "content-type":"application/json",
                    "Authorization":`Bearer ${localStorage.getItem("token")}`
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
             if(!response.ok)
                this.handleResponseError(response);
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }

    async removeNetwork(networkId, callback){
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_ad_network/deleteAdAccount`,
            {
                method:"put",
                mode:"cors",
                headers:{
                    "content-type":"application/json",
                    "Authorization":`Bearer ${localStorage.getItem("token")}`
                },
                body:JSON.stringify({
                    id:networkId
                })
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            return response.text();
        }).then((data)=>{
            callback(data);
        })
    }

    async unlinkNetwork(networkId, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_ad_network/unlink`,
            {
                method: "put",
                mode: "cors",
                headers: {
                    "Content-Type":"application/json",
                    "Accept" : "application/json",
                    "Authorization":`Bearer ${localStorage.getItem("token")}`
                },
                body:JSON.stringify({
                    id:networkId
                })
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            return response.text();
        }).then((data)=>{
            callback(data);
        })
    }

    async relinkNetwork(networkId, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}t_ad_network/relink`,
            {
                method: "put",
                mode: "cors",
                headers: {
                    "Content-Type":"application/json",
                    "Accept" : "application/json",
                    "Authorization":`Bearer ${localStorage.getItem("token")}`
                },
                body:JSON.stringify({
                    id:networkId
                })
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            return response.text();
        }).then((data)=>{
            callback(data);
        })
    }
}


export default NetworkService;

