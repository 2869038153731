import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AccountService from '../../services/AccountService';
import ConfigService from '../../services/ConfigService';
import NotificationContext from './../../context/NotificationContext';
const BillingDetails = (props) => {
    const { register, handleSubmit, errors } = useForm();
    const [isLoading = false, setLoading] = useState();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const {notify} = useContext(NotificationContext);
    useEffect(() => {
        let configService = new ConfigService();
        configService.getAllCountries((data) => {
            setCountries(data);
        })
    }, []);

    const submit = (data, event) => {
        event.preventDefault();
        setLoading(true);
        let billingDetails = {
            account_name: data.accountName,
            address: data.address,
            street_1: data.street_1,
            street_2: data.street_2,
            zip_code: data.zipcode,
            country: data.country,
            state: data.state,
            city: data.city,
            rToken: localStorage.getItem("rToken")
        }
        if(props.recoveredRegistration){
            let accountService = new AccountService();
            accountService.updateBillingDetails(billingDetails, (data) => {
                if (data.success) {
                   props.levelUp();
                } else {
                    notify({
                        title: "Failure",
                        message: `Failed to update try again.`
                    })
                }
                setLoading(false);
            })
        }else{
            let accountService = new AccountService();
            accountService.storeBillingDetails(billingDetails, (data) => {
                if (data.success) {
                    localStorage.setItem("rToken", data.rToken);
                    props.levelUp();
                } else {
                    notify({
                        title: "Failure",
                        message: `Failed to update try again.`
                    })
                }
                setLoading(false);
            })
        }
    }

    const loadStates = (value) => {
        if (value !== null) {
            let configService = new ConfigService();
            configService.getAllStates(value, (data) => {
                setStates(data);
            })
        } else {
            setStates([]);
        }
    }

    const loadCities = (value) => {
        if (value !== null) {
            let configService = new ConfigService();
            configService.getAllCity(value, (data) => {
                setCities(data);
            })
        } else {
            setCities([]);
        }
    }

    return (
        <div className="fullscreen d-flex align-items-center justify-content-center">
            <div className="form-container">
                <h5>Datos de facturación</h5>
                <div className="form">
                    <form onSubmit={handleSubmit(submit)} noValidate autoComplete="off">
                        <div className="form-group">
                            <label htmlFor="inputFieldAccountName">Nombre de cuenta</label>
                            <input
                                type="text"
                                id="inputFieldAccountName"
                                name="accountName"
                                className="form-control form-control-sm"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: "Please enter account name"
                                    },
                                    minLength: {
                                        value: 5,
                                        message: "Account name is too short, please enter correct account name"
                                    }
                                })}
                            />
                            {
                                errors.accountName
                                &&
                                (
                                    <div className="error-message text-danger">{errors.accountName.message}</div>
                                )
                            }
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputFieldAddress">Dirección</label>
                            <input
                                type="text"
                                id="inputFieldAddress"
                                name="address"
                                className="form-control form-control-sm"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: "Please enter address"
                                    },
                                    minLength: {
                                        value: 10,
                                        message: "Address is too short, please enter correct address"
                                    }
                                })}
                            />
                            {
                                errors.address
                                &&
                                (
                                    <div className="error-message text-danger">{errors.address.message}</div>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFieldStreet_1">Calle 1</label>
                            <input
                                type="text"
                                id="inputFieldStreet_1"
                                name="street_1"
                                className="form-control form-control-sm"
                                ref={
                                    register({
                                        required: {
                                            value: true,
                                            message: "Please enter street address"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "Street address is too short, please enter correct street address"
                                        }
                                    })
                                }
                            />
                            {
                                errors.street_1
                                &&
                                (
                                    <div className="error-message text-danger">{errors.street_1.message}</div>
                                )
                            }
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputFieldStreet_2">Calle 2</label>
                            <input
                                type="text"
                                id="inputFieldStreet_2"
                                name="street_2"
                                className="form-control form-control-sm"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFieldZipCode">Código postal</label>
                            <input
                                type="text"
                                id="inputFieldZipCode"
                                name="zipcode"
                                className="form-control form-control-sm"
                                ref={
                                    register({
                                        required: {
                                            value: true,
                                            message: "Please enter zipcode"
                                        }
                                    })
                                }
                            />
                            {
                                errors.zipcode
                                &&
                                (
                                    <div className="error-message text-danger">{errors.zipcode.message}</div>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFieldCountry">País</label>
                            <select
                                id="inputFieldCountry"
                                name="country"
                                className="form-control form-control-sm"
                                onChange={(event) => loadStates(event.target.value)}
                                ref={
                                    register({
                                        required: {
                                            value: true,
                                            message: "Please select country"
                                        }
                                    })
                                }
                            >
                                <option>--seleccionar--</option>
                                {
                                    countries.map((ele, index) => {
                                        return <option key={"country_option_" + index} value={ele.id}>{ele.country_name}</option>
                                    })
                                }
                            </select>
                            {
                                errors.country
                                &&
                                (
                                    <div className="error-message text-danger">{errors.country.message}</div>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFieldState">Estado</label>
                            <select
                                id="inputFieldState"
                                name="state"
                                className="form-control form-control-sm"
                                onChange={(event) => loadCities(event.target.value)}
                                ref={
                                    register({
                                        required: {
                                            value: true,
                                            message: "Please select state"
                                        }
                                    })
                                }
                            >
                                <option>--seleccionar--</option>
                                {
                                    states.map((ele, index) => {
                                        return <option key={"country_option_" + index} value={ele.id}>{ele.state_name}</option>
                                    })
                                }
                            </select>
                            {
                                errors.state
                                &&
                                (
                                    <div className="error-message text-danger">{errors.city.message}</div>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFieldCity">Ciudad</label>
                            <select
                                id="inputFieldCity"
                                name="city"
                                className="form-control form-control-sm"
                                ref={
                                    register({
                                        required: {
                                            value: true,
                                            message: "Please select city"
                                        }
                                    })
                                }
                            >
                                <option>--seleccionar--</option>
                                {
                                    cities.map((ele, index) => {
                                        return <option key={"country_option_" + index} value={ele.id}>{ele.city_name}</option>
                                    })
                                }
                            </select>
                            {
                                errors.city
                                &&
                                (
                                    <div className="error-message text-danger">{errors.city.message}</div>
                                )
                            }
                        </div>
                        <div className="row">
                            {/* <div className="col">
                                <button onClick={()=>props.levelDown()} className="btn btn-link compact-link">Edit Personal details</button>
                            </div> */}
                            <div className="col text-right">
                                <button className="btn btn-sm btn-success">
                                    {
                                        (isLoading === false || isLoading === undefined) ?
                                            <span>Siguiente                                            </span>
                                            :
                                            <span role="spinner" className="spinner-border spinner-border-sm"></span>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BillingDetails;