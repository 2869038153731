import { Link } from "react-router-dom";

const InitialAccountSetup=()=>{
    return(
        <div className="fullscreen d-flex align-items-center justify-content-center">
            <div className="form-container">
                <h5>Account setup</h5>
                {/*<p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</p>*/}
                <div className="form">
                    <form>
                        <div className="form-group">
                            <label htmlFor="inputFieldNetworkType">Network</label>
                            <select
                                id="inputFieldNetworkType"
                                name="networkType"
                                className="form-control form-control-sm"
                            >
                                <option>--Seleccionar--</option>
                                <option>AdWords</option>
                                <option>AdRoll</option>
                                <option>Microsoft Ads   </option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="inputFieldNetworkId">Network Id</label>
                            <input 
                                type="text"
                                id="inputFieldNetworkId"
                                name="networkId"
                                className="form-control form-control-sm"
                            />
                        </div>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-link btn-sm compact-link">
                                    <Link to="/adblock/home">Skip, I'll do it later</Link>
                                </button>
                            </div>
                            <div className="col text-right">
                                <button className="btn btn-success btn-sm">Finish</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default InitialAccountSetup;