import {useEffect, useState} from 'react';
import ReportService from '../services/ReportService';
import NumberCounter from './NumberCounter';
const HomeSummary=(props)=>{
    // const [reportDurationIndex, setReportDuration] = useState(0);
    // const reportDuration=[
    //     { level: 1, caption: "Today" },
    //     { level: 2, caption: "This Week" },
    //     { level: 3, caption: "This Month" },
    //     { level: 4, caption: "This Year" },
    // ];

    /** 
     * <NumberCounter number={ele.count} _prefix={ele.currency?ele.currency:''} _suffix={""} />
    */
    const [blockReport,setState]=useState([

        { caption:"Dominios excluidos", count:0 , tooltip:'Colocación infractora añadida a la lista de exclusión.'},
        { caption:"Dominios en proceso", count:0, tooltip:'Colocación infractora en curso o no añadida la lista de exclusión.'},
        { caption:"Gastos anteriores", count:0, currency: null, tooltip:'Importe gastado en colocación infractora en el pasado y ahora impide colocar anuncios en dichas ubicaciones.'},
        { caption:"Gastos actuales", count:0, currency: null, tooltip:'Importe actual o pasado gastado en colocación infractora, pero sin añadir aún la lista de exclusión para evitar la colocación de anuncios en sitio infractor en el futuro.'}
    ]);
    // const ovrd_setReportDuration=()=>{
    //     if(reportDuration.length-1===reportDurationIndex)
    //         setReportDuration(0);
    //     else
    //         setReportDuration(reportDurationIndex+1);
    // }
    useEffect(()=>{
        let reportService = new ReportService();
        reportService.getAdSpent((data)=>{
            if(data !== null){
                
                blockReport[2].currency = data.currency;            
                blockReport[3].currency = data.currency;            
                blockReport[2].count = data.pastSpent !== null ? data.pastSpent : 0;
                blockReport[3].count = data.ongoingSpent !== null ? data.ongoingSpent : 0;
                setState(blockReport);
            }
        });

        reportService.getBlockUnblockCount((data)=>{
            if(data !== null){
                blockReport[0].count = data.block !== null ? data.block : 0;
                blockReport[1].count = data.unblock !== null ? data.unblock : 0;
                setState(blockReport);
            }
            //console.log('########### data',data)
            //console.log('########### block',blockReport[0].count)
            //console.log('########### unblock',blockReport[1].count)
        })
    }, [])
    // const reportDurationLabel = reportDuration[reportDurationIndex].caption;
    return(
        <div className="summary">
            <div className="row">
                <div className="container-fluid">
                    <h5 className="semi-heading">
                    Proteja su publicidad: Gestione la reputación de su marca.
                    </h5>
                </div>
                <div className="col text-right">
                    {/* <label onClick={()=>ovrd_setReportDuration()}>{reportDurationLabel}</label> */}
                </div>
            </div>
            <div className="counter-block-list">
                <div className="list">
                    {
                        blockReport.map((ele, index)=>{
                           // console.log('ele ',ele)
                            return (
                                <div key={"counter_block_" + index} title={ele.tooltip} className="counter-block">
                                    <h2>
                        
                                      <span className="number-count">{ele.currency?ele.currency:''}{ele.count} </span>
                                    </h2>
                                    <h4>{ele.caption}</h4>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default HomeSummary;