import React from 'react';
import LoginForm from './LoginForm';

const Login=()=>{
    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col highlight-background">
                    <div className="fullscreen d-flex align-items-center justify-content-center">
                        
                    </div>
                </div>
                <div className="col">
                    <LoginForm />
                </div>
            </div>
        </div>
    )
}

export default Login;