import Service from "./Service"

class ReportService extends Service{
    async getAdSpent(callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}graphs/getAdSpent`,
            {
                method:"GET",
                mode: "cors",
                headers:{
                    "content-type":"application/json",
                    "Authorization" : `Bearer ${localStorage.getItem("token")}`
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                alert("Session Expire, Please login again.");
                window.location = '/login';
                return;
            }
            if(!response.ok)
                this.handleResponseError(response);
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }
    async getBlockUnblockCount(callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}graphs/getBlockUnBlockCount`,
            {
                method:"GET",
                mode: "cors",
                headers:{
                    "content-type":"application/json",
                    "Authorization" : `Bearer ${localStorage.getItem("token")}`
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                //alert("Session Expire, Please login again.");
                //window.location = '/login';
                return;
            }
            if(!response.ok)
                this.handleResponseError(response);
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }

    async getPieChart(callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}graphs/getPieChart`,
            {
                method:"GET",
                mode: "cors",
                headers:{
                    "content-type":"application/json",
                    "Authorization" : `Bearer ${localStorage.getItem("token")}`
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                //alert("Session Expire, Please login again.");
                //window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }

    async getBarGraph(callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}graphs/getBarGraph`,
            {
                method:"GET",
                mode: "cors",
                headers:{
                    "content-type":"application/json",
                    "Authorization" : `Bearer ${localStorage.getItem("token")}`
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
               //alert("Session Expire, Please login again.");
                //window.location = '/login';
                return;
            }
            if(!response.ok){
                this.handleResponseError(response);
            }
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }

    // async getTabularData(pageNo, callback){
    //     fetch(
    //         `${process.env.REACT_APP_ACCESS_POINT}graphs/getTabularData/${pageNo}`,
    //         {
    //             method:"GET",
    //             mode: "cors",
    //             headers:{
    //                 "content-type":"application/json",
    //                 "Authorization" : `Bearer ${localStorage.getItem("token")}`
    //             }
    //         }
    //     ).then((response)=>{
    //         return response.json();
    //     }).then((data)=>{
    //         callback(data);
    //     })
    // }

    async getTabularData(page, callback){
        fetch(
            `${process.env.REACT_APP_ACCESS_POINT}graphs/getTabularData?pageNo=${page.pageNo}&search=${page.query}`,
            {
                method:"GET",
                mode: "cors",
                headers:{
                    "content-type":"application/json",
                    "Authorization" : `Bearer ${localStorage.getItem("token")}`
                }
            }
        ).then((response)=>{
            if(response.status == '401'){
                localStorage.removeItem("token");
                //alert("Session Expire, Please login again.");
                //window.location = '/login';
                return;
            }
            if(!response.ok)
                this.handleResponseError(response);
            return response.json();
        }).then((data)=>{
            callback(data);
        })
    }
}

export default ReportService;