import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import BlockListService from "../services/BlockListService";
import NotificationContext from './../context/NotificationContext';
const UploadBlocklist = (props) => {
    const [isUploading, setUploading] = useState(false);
    useEffect(() => {
        props.setModuleHeading("Cargar lista de bloqueo");
        let div = dropRef.current;
        div.addEventListener('dragenter', handleDragIn);
        div.addEventListener('dragleave', handleDragOut);
        div.addEventListener('dragover', handleDrag);
        div.addEventListener('drop', handleDrop);

        return () => {
            div.removeEventListener('dragenter', handleDragIn)
            div.removeEventListener('dragleave', handleDragOut)
            div.removeEventListener('dragover', handleDrag)
            div.removeEventListener('drop', handleDrop)
        }
    }, [])
    const [drag, setDragState] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);
    const [files, setFiles] = useState([]);
    const dropRef = useRef(null);
    const {notify} = useContext(NotificationContext);
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(dragCounter + 1);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0)
            setDragState(true);
    }

    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(dragCounter - 1);
        if (dragCounter === 0)
            setDragState(false);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragState(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setFiles(e.dataTransfer.files);
            console.log(e.dataTransfer.files)
            e.dataTransfer.clearData();
            setDragCounter(0);
        }
    }
    const onFileChange = event => {
        const fileExt = event.target.files[0].name.split('.').pop();
        if (fileExt === "csv") {
            setFiles([]);
            setFiles(event.target.files);
        }
        else {
            notify({
                type: 'info',
                title: "Info",
                message: `Invalid file format. Select correct file type.`
            })
            return false;
        }
    }
    const upload = (event) => {
        if (files[0] !== null && files[0] !== undefined) {
            setUploading(true);
            var f = files[0];
            // var name = f.name;
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });

                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });

                // const uploadData = convertToJson(data);
                convertToJson(data);

            };
            reader.readAsBinaryString(f);
        }
        else {
            notify({
                type: 'info',
                title: "Información",
                message: `Seleccione el archivo para cargar.`
            })
            setUploading(false);
        }

    }

    const convertToJson = (csv) => {
        var lines = csv.split("\n");
        var result = [];
        var headers = lines[0].split(",");
        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(",");
            if(currentline[0].trim() !== ''){
                for (var j = 0; j < headers.length; j++) {
                    obj[headers[j]] = currentline[j];
                }
                if(obj.domain.trim() !== ""){
                    obj.domain = obj.domain.replace("www.","");
                    obj.domain = obj.domain.replace("http://","");
                    obj.domain = obj.domain.replace("https://","");
                    result.push(obj);
                }
            }
        }

        let blockListService = new BlockListService();
        blockListService.uploadBlackList(JSON.stringify(result), (resp) => {
            if (resp === "Success") {
                setFiles([]);
                notify({
                    type: 'success',
                    title: "Success",
                    message: `New blocklist uploaded.`
                })
            } else {
                notify({
                    type: 'failure',
                    title: "Failure",
                    message: `Failed to upload. Try again.`
                })
            }
            setUploading(false);
        })
    }
    return (
        <div className="upload-panel">
            <div className="row">
                <div className="col">
                    <label className="d-block" htmlFor="fileUploader">
                        <div className="d-flex align-items-center justify-content-center fileUploadContainer" ref={dropRef}>
                            <div className="overlay">
                                {drag &&
                                    <div className="drag-box">
                                        drop here :)
                            </div>
                                }
                                {
                                    files.length > 0 &&
                                    <span>{files[0].name}</span>
                                }
                                {
                                    (files.length === 0 && drag === false) &&
                                    <span>Haga clic aquí para seleccionar archivos</span>
                                }
                            </div>
                        </div>
                    </label>
                    <div className="text-center">
                        <input
                            id="fileUploader"
                            type="file"
                            name="file"
                            accept=".csv"
                            className="hidden"
                            onClick={(event) => { event.target.value = null }}
                            onChange={(event) => onFileChange(event)} />
                    </div>
                    <div className="uploader-control">
                        <div className="row ">
                            <div className="col">
                                <button
                                    onClick={(event) => upload(event)}
                                    type="submit"
                                    className="btn btn-success">
                                    {
                                        isUploading ?
                                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                            :
                                            "Cargar"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col background-highlight">
                    <div className=" text-center">
                        <h5 className="semi-heading">Puede descargar un archivo de muestra aquí</h5>
                        <Link className="btn btn-sm btn-success" to="/Example.xlsx" target="_blank" download>Descargar</Link>
                    </div>
                    <br></br>
                    <div class="alert alert-success" role="alert">
                        <h5 class="alert-heading">¡Consejos importantes!</h5>
                        <p>Siempre que necesite estar seguro, consulte el archivo de muestra.</p>
                        <hr />
                        <p class="mb-0">Utilice dominio en lugar de subdominio o direcciones URL siempre que sea posible para que los anuncios puedan bloquearse en todo el sitio web.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UploadBlocklist;
