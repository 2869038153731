import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';
import AuthContext from '../../context/AuthContext';
const Intro = props => {
    const { auth } = useContext(AuthContext);

    return (
        <div className="intro">
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <Logo />
                        </div>
                        <div className="col text-right">
                            {
                                auth.isLoggedIn ?
                                    <Link className="btn btn-success btn-sm" to="/adblock/home">Move to Dashboard</Link>
                                    :
                                    <Link className="btn btn-success btn-sm" to="/login">Login</Link>
                            }
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}
export default Intro;