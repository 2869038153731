import { useForm } from "react-hook-form";
import React, { useState, useRef } from 'react';
import AccountService from "../../services/AccountService";
import { Link } from "react-router-dom";

const PasswordUpdate = (props) => {
    const { register, handleSubmit, errors, watch } = useForm();
    const password = useRef({});
    password.current = watch("password", "")
    const [isLoading, setLoading] = useState(false)
    const [response, setResponse] = useState(undefined);
    const submit = (data, event) => {
        const details = {
            newPassword: data.password,
            verifyToken: "" + window.location.href.split("/").pop()
        }
        setLoading(true);
        let accountService = new AccountService();
        accountService.resetPassword(details, (data)=>{
            setResponse(data);
            setLoading(false);
        })
    }
    return (
        <div className="fullscreen d-flex align-items-center justify-content-center">
            {
                response === undefined?
                    <div className="">
                        <form onSubmit={handleSubmit(submit)} style={{ width: "300px" }} autoComplete="off" noValidate>
                            <div className="row">
                                <div className="col">
                                    <h4>Pirates Alert Adblock</h4>
                                    <p>Password Reset</p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputFieldPassword">Password</label>
                                <input
                                    type="password"
                                    id="inputFieldPassword"
                                    className="form-control form-control-sm"
                                    name="password"
                                    aria-describedby="Password"
                                    ref={register({
                                        required: "You must specify a password",
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters"
                                        }
                                    })}
                                />
                                {errors.password && (<div className="error-message text-danger">{errors.password.message}</div>)}
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputFieldConfirmPassword">Confirm Password</label>
                                <input
                                    type="password"
                                    id="inputFieldConfirmPassword"
                                    className="form-control form-control-sm"
                                    name="confirmPassword"
                                    aria-describedby="Confirm Password"
                                    ref={register({
                                        validate: value =>
                                            value === password.current || "The passwords do not match"
                                    })}
                                />
                                {errors.confirmPassword && (<div className="error-message text-danger">{errors.confirmPassword.message}</div>)}
                            </div>
                            <div >
                                <button className="btn btn-sm btn-success">
                                    {
                                        isLoading ? 
                                        <i className="fa-spin fa fa-spinner" aria-hidden="true"></i>
                                        :
                                        "Change Password"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                    :
                    <div>
                        {
                            response.success === true ? 
                            <>
                                <div class="alert alert-success">
                                    <strong>Success!</strong> Congratulations, your password has beed changed. Try Login with new password.
                                </div>
                                <Link className="btn btn-sm btn-success" to="/login">Login</Link>
                            </>
                            :
                            <>
                                <div class="alert alert-danger">
                                    <strong>Failed!</strong> Failed to update your password.
                                </div>
                            </>
                        }
                    </div>
            }
        </div>
    )
}

export default PasswordUpdate;