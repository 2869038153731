import { useContext, useEffect, useState } from "react"
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import NotificationContext from "../../context/NotificationContext";
import SuppportService from "../../services/SupportService";
const NewQueryForm = props =>{
    const {notify} = useContext(NotificationContext)
    const {register, handleSubmit, errors} = useForm();
    const history = useHistory();
    const [isLoading, setLoading]=useState(false);
    useEffect(()=>{
        props.setModuleHeading("Nueva consulta");
    },[]);
    const submit=(data, event)=>{
        setLoading(true);
        let supportService = new SuppportService();
        const query = {
            query_title : data.querySubject,
            query_body : data.queryMessage
        }
        supportService.storeQuery(query, (data)=>{
            if(data.ticket_id !== null && data.ticket_id !== undefined){
                notify({
                    type: 'success',
                    title: "¡Hecho!",
                    message: `Se ha enviado el nuevo ticket. El ID de tu ticket es ${data.ticket_id}`
                })
                history.push("/adblock/support/");
            }
        })
    }
    return(
        <div className="panel">
            <div className="row">
                <div className="col">
                    <div className="form-container">
                        <h3>Introduce los datos de la consulta</h3>
                        <p>Para una resolución rápida de la consulta, introduce el asunto y el mensaje correctamente. Es preferible un mensaje detallado.</p>
                        <hr></hr>
                        <div className="form">
                            <form onSubmit={handleSubmit(submit)} noValidate autoComplete="off">
                                <div className="form-group">
                                    <label htmlFor="inputFieldMessageSubject">Asunto</label>
                                    <input
                                        type="text"
                                        name="querySubject"
                                        className="form-control  form-control-sm"
                                        id="inputFieldMessageSubject"
                                        ref={
                                            register({
                                                required:{
                                                    value: true,
                                                    message: "Introducir asunto de consulta"
                                                }
                                            })
                                        }
                                    />
                                    {
                                        errors.querySubject &&
                                        (<span className="text-danger">{errors.querySubject.message}</span>)
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputFieldMessagebody">Mensaje</label>
                                    <textarea
                                        type="text"
                                        name="queryMessage"
                                        className="form-control  form-control-sm"
                                        id="inputFieldMessagebody"
                                        style={{minHeight: "200px"}}
                                        ref={
                                            register({
                                                required:{
                                                    value: true,
                                                    message: "Introduce la consulta"
                                                }
                                            })
                                        }
                                    ></textarea>
                                    {
                                        errors.queryMessage &&
                                        (<span className="text-danger">{errors.queryMessage.message}</span>)
                                    }
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="inputFieldAttachment">Attachment</label>
                                    <input
                                        type="file"
                                        name="querySubject"
                                        className="form-control  form-control-sm"
                                        id="inputFieldAttachment"
                                    />
                                </div> */}
                                <div className="form-group">
                                    <button 
                                        disabled={isLoading?true:null}
                                        type="submit"
                                        className="btn btn-success btn-block">
                                        {
                                            isLoading?
                                            <i className="fa-spin fa fa-spinner" aria-hidden="true"></i>
                                            :
                                            "Enviar"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col ">
                Cómo funciona
                </div>
            </div>
        </div>
    )
}

export default NewQueryForm;