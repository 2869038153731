//import { useContext} from 'react';
//import AuthContext  from '../context/AuthContext';
//import NotificationContext from "./../context/NotificationContext";
class Service{
    //handleResponseError =(response)=> {
   //function HandleResponseError(response){
    handleResponseError(response){
        console.log('************************** Resonse handleResponseError ',response);
        //const { setAuth} = useContext(AuthContext);
       // const data = JSON.parse(response);
        //if( response.status ===403 || data.msg==='Token expire'){
        if( response.status ===401 || response.status ===403 ){
            //alert('Token expire')
            localStorage.removeItem("token");
             /* 
                setAuth({
                    isLoggedIn: false,
                    userData: undefined
                })
                */
                
        }
        // throw new Error("" + response);
    }
    handleErrpr(error){
        console.log(error);
    }
}
export default Service;
//export default HandleResponseError;