import React from 'react';
import AccountTypeSelectionForm from './AccontTypeSelectionForm';
import AccountVerificationForm from './AccountVerificationForm';
import BillingDetails from './BillingDetailsForm';
import PersonalDetailsForm from './PersonalDetailsForm';
import InitialAccountSetup from './InitialAccountSetup';
class Registration extends React.Component{
    constructor(props){
        super(props);
        this.state={
            activeFormIndex: 0,
            selectedAccountType: -1,
            accountId: 0,
            recoveredRegistration: false
        }
        this.selectAccountType = this.selectAccountType.bind(this);
        this.levelDown = this.levelDown.bind(this);
        this.levelUp = this.levelUp.bind(this);
        this.setToken = this.setToken.bind(this);
    }
    componentDidMount(){
        const loc = parseInt(window.location.toString().split("?").pop().split("=").pop());
        if(loc>1){
            this.setState({
                activeFormIndex: loc,
                recoveredRegistration: true
            })
        }
    }
    selectAccountType=(accountType)=>{
        let {selectedAccountType, activeFormIndex} = this.state;
        selectedAccountType= accountType;
        activeFormIndex++;
        this.setState({
            selectedAccountType: selectedAccountType,
            activeFormIndex: activeFormIndex
        })
    }
    setToken=(token)=>{
        let accountId = this.state.accountId;
        accountId = token;
        this.setState({
            accountId: accountId
        })
    }
    levelDown=()=>{
        let activeFormIndex = this.state.activeFormIndex;
        activeFormIndex-=1;
        this.setState({
            activeFormIndex: activeFormIndex
        })
    }
    levelUp=()=>{
        let activeFormIndex = this.state.activeFormIndex;
        activeFormIndex+=1;
        this.setState({
            activeFormIndex: activeFormIndex
        })
    }
    render(){
        const {activeFormIndex, selectedAccountType} = this.state;
        let activeForm = null;
        switch (activeFormIndex) {
            case 0:{
                activeForm = <AccountTypeSelectionForm selectAccountType={this.selectAccountType} />
                break;
            }
            case 1:{
                activeForm = <PersonalDetailsForm 
                    accountType={selectedAccountType} 
                    levelUp={this.levelUp} 
                    levelDown={this.levelDown}/>
                break;
            }
            case 2:{
                activeForm = <BillingDetails
                    recoveredRegistration={this.recoveredRegistration}
                    levelUp={this.levelUp} 
                    levelDown={this.levelDown}/>
                break;
            }
            case 3:{
                activeForm = <AccountVerificationForm 
                    recoveredRegistration={this.recoveredRegistration}
                    levelUp={this.levelUp} 
                    levelDown={this.levelDown}/>
                break;
            }
            case 4:{
                activeForm = <InitialAccountSetup />
                break;
            }
            default:
                break;
        }
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col highlight-background">
                        <div className="fullscreen d-flex align-items-center justify-content-center">
                            
                        </div>
                    </div>
                    <div className="col">
                        {
                            activeForm === null ?
                            <div className="fullscreen d-flex align-items-center justify-content-center">
                                <h3>Something went wrong, please try after refreshing page.</h3>
                            </div>
                            :
                            activeForm
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Registration;