import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ConfigService from "../../services/ConfigService";
import SettingService from "./../../services/SettingService"
import NotificationContext from './../../context/NotificationContext'
const BillingSetting = props => {
    const {register, handleSubmit, setValue} = useForm();
    const [isLoading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const {notify} = useContext(NotificationContext);
    useEffect(()=>{
        
        props.setModuleHeading("Billing");
        loadDetails();

    }, [])
    const loadDetails=()=>{
        let settingService = new SettingService();
        let c = 0; 
        let s = 0; 
        let ct = 0; 
        settingService.getBillingDetails((data)=>{
            setValue("accountName", data.account_name);
            setValue("address", data.address);
            setValue("street_1", data.street_1);
            setValue("street_2", data.street_2);
            setValue("zipCode", data.zip_code);
            c= data.country;
            s= data.state;
            ct= data.city;
            let configService = new ConfigService();
            configService.getAllCountries((data)=>{
                setCountries(data);
                setValue("country", c);
                configService.getAllStates(c, (data)=>{
                    setStates(data);
                    setValue("state", s);
                    configService.getAllCity(s, (data)=>{
                        setCities(data);
                        setValue("city", ct);
                    })
                })
            })
        });
    }
    const loadStates=(value)=>{
        if(value !== null){
            let configService = new ConfigService();
            configService.getAllStates(value, (data)=>{
                setStates(data);
            })
        }else{
            setStates([]);
        }
    }

    const loadCities=(value)=>{
        if(value !== null){
            let configService = new ConfigService();
            configService.getAllCity(value, (data)=>{
                setCities(data);
            })
        }else{
            setCities([]);
        }
    }
    const submit=(data, event)=>{
        event.preventDefault();
        setLoading(true);
        let settingService = new SettingService();
        let billing = {
            account_name: data.accountName,
            address: data.address,
            street_1: data.street_1,
            street_2: data.street_2,
            zip_code: data.zipCode,
            country: data.country,
            state: data.state,
            city: data.city
        }
        settingService.updateBillingDetails(billing, (data)=>{
            if(data){
                setLoading(true);
                notify({
                    title: "¡Hecho!",
                    message: `Detalles actualizados.`
                })
                loadDetails();
            }
        })
    }
    return (
        <div>
            <div className="panel">
                <div className="row">
                    <div className="col">
                        <h5 className="semi-heading">Administra tus datos de facturación.</h5>
                        <div className="form-container">
                            <div class="form">
                                {/* <label>Personal Details</label> */}
                                <form onSubmit={handleSubmit(submit)} noValidate autoComplete="off">
                                    <div class="form-group">
                                        <label htmlFor="inputFieldAccountName">Nombre de cuenta</label>
                                        <input 
                                            type="text"
                                            id="inputFieldAccountName"
                                            class="form-control form-control-sm"
                                            name="accountName"
                                            ref={register({
                                                required:{
                                                    value:true,
                                                    message: "Enter account name"
                                                }
                                            })}
                                        />
                                    </div>
                                    <hr></hr>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldAddress">Dirección</label>
                                        <input 
                                            type="text"
                                            id="inputFieldAddress"
                                            class="form-control form-control-sm"
                                            name="address"
                                            ref={register({
                                                required:{
                                                    value:true,
                                                    message: "Enter address"
                                                }
                                            })}
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldStreet1">Calle 1</label>
                                        <div className="row">
                                            <div className="col">
                                                <input 
                                                    type="text"
                                                    id="inputFieldStreet1"
                                                    class="form-control form-control-sm"
                                                    name="street_1"
                                                    ref={register({
                                                        required:{
                                                            value:true,
                                                            message: "Enter street address"
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldStreet2">Calle 2</label>
                                        <div className="row">
                                            <div className="col">
                                                <input 
                                                    type="text"
                                                    id="inputFieldStreet2"
                                                    class="form-control form-control-sm"
                                                    name="street_2"
                                                    ref={register({
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldZipCode">Código postal</label>
                                        <input 
                                            type="text"
                                            id="inputFieldZipCode"
                                            class="form-control form-control-sm"
                                            name="zipCode"
                                            ref={register({
                                                required:{
                                                    value:true,
                                                    message: "Enter zip code"
                                                }
                                            })}
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldCountry">País</label>
                                        <select 
                                            type="text"
                                            id="inputFieldCountry"
                                            class="form-control form-control-sm"
                                            name="country"
                                            onChange={(event)=>loadStates(event.target.value)}    
                                            ref={register({
                                            })}
                                        >
                                            <option>--seleccionar--</option>
                                            {
                                                countries.map((ele, index)=>{
                                                    return <option key={"country_option_" + index} value={ele.id}>{ele.country_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldState">Estado</label>
                                        <select 
                                            id="inputFieldState"
                                            class="form-control form-control-sm"
                                            name="state"
                                            onChange={(event)=>loadCities(event.target.value)}
                                            ref={register({
                                                
                                            })}
                                        >
                                            <option>--seleccionar--</option>
                                            {
                                                states.map((ele, index)=>{
                                                    return <option key={"country_option_" + index} value={ele.id}>{ele.state_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldCity">Ciudad</label>
                                        <select 
                                            id="inputFieldCity"
                                            class="form-control form-control-sm"
                                            name="city"
                                            ref={register({
                                                
                                            })}
                                        >
                                            <option>--seleccionar--</option>
                                            {
                                                cities.map((ele, index)=>{
                                                    return <option key={"country_option_" + index} value={ele.id}>{ele.city_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <button 
                                                    type="submit"
                                                    className="btn btn-success ">
                                                        {
                                                            isLoading ?
                                                            <i className="fa-spin fa fa-spinner" aria-hidden="true"></i>
                                                            :
                                                            "Update"
                                                        }
                                                    </button>
                                            </div>
                                            {/* <div className="col text-right">
                                                <button className="btn btn-link">Revert Changes</button>
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BillingSetting;