import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import BillingSetting from "./BillingSetting";
import ProfileSetting from "./ProfileSetting";
class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            moduleHeading: "Personal Details",
            headingAnimationClass: ""
        }
    }
    componentDidMount() {
        this.props.setAppHeading("Configuración");
    }
    setModuleHeading = (heading) => {
        let { moduleHeading, headingAnimationClass } = this.state;
        let intervalCounter = 0;
        const animationInterval = setInterval(() => {
            intervalCounter++;
            switch (intervalCounter) {
                case 1: {
                    headingAnimationClass = "fade-out";
                    break;
                }
                case 3: {
                    moduleHeading = heading;
                    break;
                }
                case 4: {
                    headingAnimationClass = "fade-in";
                    break;
                }
                case 5: {
                    clearInterval(animationInterval);
                    break;
                }
                default:
                    break;
            }
            this.setState({
                moduleHeading: moduleHeading,
                headingAnimationClass: headingAnimationClass
            })
        }, 200)
    }
    render() {
        const { moduleHeading, headingAnimationClass } = this.state;
        return (
            <div className="module">
                <div className="module-header">
                    <div className="row">
                        <div className="col">
                            <h5 className={`${headingAnimationClass} animate semi-heading `}>{moduleHeading}</h5>
                        </div>
                        <div className="col text-right">

                        </div>
                    </div>
                </div>
                <div className="module-body">
                    <div className="row">
                        <div className="col-md-3">
                            <ul className="module-options vertical">
                                <li>
                                    <NavLink to="/adblock/setting/personal">
                                        <label>Inicio</label>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/adblock/setting/billing">
                                        <label>Dirección</label>
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/adblock/setting/account">
                                        <label>Account</label>
                                    </NavLink>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col">
                            <Switch>
                                <Route path="/adblock/setting/profile">
                                    <ProfileSetting setModuleHeading={this.setModuleHeading} />
                                </Route>
                                {/* <Route path="/adblock/setting/account">
                                    <AccountSetting setModuleHeading={this.setModuleHeading} />
                                </Route> */}
                                <Route path="/adblock/setting/billing">
                                    <BillingSetting setModuleHeading={this.setModuleHeading} />
                                </Route>
                                <Route path="/adblock/setting/">
                                    <ProfileSetting setModuleHeading={this.setModuleHeading} />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Setting;