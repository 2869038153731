import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import SettingService from "../../services/SettingService";
import NotificationContext from './../../context/NotificationContext';
const ProfileSetting = props => {
    const { register, handleSubmit, errors, setValue } = useForm();
    useEffect(() => {
        props.setModuleHeading("Perfil");
        loadDetails();
    }, [])
    const {notify} = useContext(NotificationContext);
    const loadDetails=()=>{
        let settingService = new SettingService();
        settingService.getPersonalDetails((data) => {
            setValue("firstName", data.first_name);
            setValue("lastName", data.last_name);
            setValue("emailAddress", data.email_address);
            setValue("isVerified", data.is_varified);
            setValue("phoneNumber", data.phone_number)
            setValue("lastPasswordUpdate", data.password_updated_on)
        });
    }
    const submit = (data, event) => {
        event.preventDefault();
        let user = {
            first_name: data.firstName,
            last_name: data.lastName,
            email_address: data.emailAddress,
            phone_number: data.phoneNumber,
            account_avatar_url: "NA"
        }
        let settingService = new SettingService();
        settingService.updatePersonalDetails(user, (data)=>{
            if(data){
                notify({
                    title: "Success",
                    message: `Details updated.`
                })
                loadDetails();
            }
        })
    }
    return (
        <div>
            <div className="panel">
                <div className="row">
                    <div className="col">
                        <h5 className="semi-heading">Administra tus datos personales y avatar.</h5>
                        <div className="form-container">
                            <div class="form">
                                {/* <label>Personal Details</label> */}
                                <form onSubmit={handleSubmit(submit)}>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldFirstName">Nombre</label>
                                        <input
                                            type="text"
                                            id="inputFieldFirstName"
                                            class="form-control form-control-sm"
                                            name="firstName"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message: "Enter first name."
                                                }
                                            })}
                                        />
                                        {
                                            errors.firstName &&
                                            (<span className="text-danger">{errors.firstName.message}</span>)
                                        }
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldLastName">Apellidos</label>
                                        <input
                                            type="text"
                                            id="inputFieldLastName"
                                            class="form-control form-control-sm"
                                            name="lastName"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message: "Enter last name."
                                                }
                                            })}
                                        />
                                        {
                                            errors.lastName &&
                                            (<span className="text-danger">{errors.lastName.message}</span>)
                                        }
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldEmailAddress">Dirección de correo electrónico</label>
                                        <div className="row">
                                            <div className="col">
                                                <input
                                                    type="email"
                                                    id="inputFieldEmailAddress"
                                                    class="form-control form-control-sm"
                                                    name="emailAddress"
                                                    readOnly
                                                    ref={register({})}
                                                />
                                            </div>
                                        </div>
                                        {/* <div>
                                            {
                                                data.isVarified === 0 ?
                                                null
                                                :
                                                <small className="text-info"><i className="fa fa-check-circle-o" aria-hidden="true"></i>&nbsp;Your account is already verified</small>
                                            }
                                        </div> */}
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="inputFieldLastName">Número de teléfono</label>
                                        <input
                                            type="text"
                                            id="inputFieldPhoneNumber"
                                            class="form-control form-control-sm"
                                            name="phoneNumber"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message: "Enter phone number."
                                                }
                                            })}
                                        />
                                        {
                                            errors.lastName &&
                                            (<span className="text-danger">{errors.lastName.message}</span>)
                                        }
                                    </div>
                                    {/* <div class="form-group">
                                        <label htmlFor="inputFieldFirstName">Change Password</label>
                                        <input
                                            type="password"
                                            id="inputFieldFirstName"
                                            class="form-control form-control-sm"
                                            name="password"
                                            ref={
                                                register({})
                                            }
                                        />
                                        <div>
                                            <small>Last password updated on </small>
                                            <input className="text-info info-label" readOnly type="text" name="lastPasswordUpdate" ref={register({})} />
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <button className="btn btn-success ">Actualizar</button>
                                            </div>
                                            {/* <div className="col text-right">
                                                <button className="btn btn-link">Revert Changes</button>
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* <div className="form-container">
                                <hr></hr>
                                <form>
                                    <div className="row d-flex align-items-center justify-content-center">
                                        <div className="col-md-3" style={{minWidth: "140px"}}>
                                            <span className="emblem avatar">
                                                <label htmlFor="inputFiledAvatar">
                                                    <span className="btn btn-sm btn-success">Change</span>
                                                    <input
                                                        id="inputFiledAvatar"
                                                        type="file"
                                                        className="d-none btn btn-sm btn-success"
                                                    />
                                                </label>
                                            </span>
                                        </div>
                                        <div className="col">
                                            <div>
                                                <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</p>
                                            </div>
                                            <div>
                                                <button className="btn btn-success">Update Avatar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileSetting;