import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
//import LoginBridge from './pages/LoginBridge';
/*
<PrivateRoute 
    path="/loginBridge"
    component={LoginBridge}
/>
*/
// import AccountRecovery from './pages/account_recovery/AccountRecovery';
// import PageNotFound from './pages/page_not_found/PageNotFound'

import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/login/Login';
import Registration from './pages/registration/Registrataion';
import Intro from './pages/Intro/Intro';
import { useContext } from 'react';
import AuthContext from './context/AuthContext'
import AccountRecovery from './pages/account_recovery/AccountRecovery'
import PasswordUpdate from './pages/account_recovery/PasswordUpdate';
import Failed from './pages/account_recovery/Failed';

const Routes = (props) =>{
    const {auth} = useContext(AuthContext);
    return(
        <Router {...props}>
            <Switch>
                <PrivateRoute 
                    path="/adblock/home"
                    auth={auth.isLoggedIn}
                    component={Dashboard}
                />
                <PrivateRoute 
                    path="/adblock/users"
                    auth={auth.isLoggedIn}
                    component={Dashboard}
                />
                <PrivateRoute 
                    path="/adblock/setting"
                    auth={auth.isLoggedIn}
                    component={Dashboard}
                />
                <PrivateRoute 
                    path="/adblock/support"
                    auth={auth.isLoggedIn}
                    component={Dashboard}
                />
                <PrivateRoute 
                    path="/adblock/invoice"
                    auth={auth.isLoggedIn}
                    component={Dashboard}
                />
                <PrivateRoute 
                    path="/adblock/blocklist"
                    auth={auth.isLoggedIn}
                    component={Dashboard}
                />
                <PrivateRoute 
                    path="/adblock/networks"
                    auth={auth.isLoggedIn}
                    component={Dashboard}
                />
                <ProtectedRoute
                    path="/login"
                    auth={auth.isLoggedIn}
                    component={Login}
                />
                <ProtectedRoute
                    path="/reset"
                    component={PasswordUpdate}
                />
                <ProtectedRoute
                    path="/failed"
                    component={Failed}
                />
                <ProtectedRoute
                    path="/registration"
                    auth={auth.isLoggedIn}
                    component={Registration}
                />
                <ProtectedRoute
                    path="/accountrecovery"
                    auth={auth.isLoggedIn}
                    component={AccountRecovery}
                />
               
                <PublicRoute
                    path="/"
                    component={Intro}
                />
            </Switch>
        </Router>
    )
};

const PrivateRoute=({auth, component:Component, ...rest})=>{
    return(
        <Route
            {...rest}
            render={()=>auth?(<Component/>):<Redirect to="/login"/>}
        />
    )
}

const ProtectedRoute=({auth, component:Component, ...rest})=>{
    return(
        <Route
            {...rest}
            render={props=>!auth?(<Component />):<Redirect to="/adblock/home"/>}
        />
    )
}
const PublicRoute=props=>{
    return(
        <Route
            render={()=>(<Intro/>)}
        />
    )
}
export default Routes;