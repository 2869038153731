import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import AccountService from '../../services/AccountService';
const PersonalDetailsForm = (props) => {
    const { register, handleSubmit, errors, watch } = useForm();
    const [isLoading, setLoading] = useState(false);
    const [tryLogin, setTryLogin] = useState(false);
    const password = useRef({});
    password.current = watch("password", "");
    const submit = (data, event) => {
        event.preventDefault();
        setLoading(true);
        let userDetails = {
            first_name: data.firstName,
            last_name: data.lastName,
            email_address: data.email,
            password: data.password,
            account_type: props.accountType
        }

        let accountService = new AccountService();
        accountService.storePersonalDetails(userDetails, (data) => {
            if (data.success) {
                localStorage.setItem("rToken", data.rToken);
                props.levelUp();
            } else {
                if (data.message === "Email Id Already Exits") {
                    setTryLogin(true);
                }
            }
            setLoading(false);
        })
    }

    return (
        <div className="fullscreen d-flex align-items-center justify-content-center">
            <div className="form-container">
                <h5>Datos personales</h5>
                <p>¿Deseas crear una cuenta para Creador de contenido o Marca?</p>
                <div className="form">
                    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
                        <div className="form-group">
                            <label htmlFor="inputFieldFirstName">Apellidos</label>
                            <input
                                type="text"
                                id="inputFieldFirstName"
                                className="form-control form-control-sm"
                                name="firstName"
                                aria-describedby="First name"
                                ref={
                                    register({
                                        required: {
                                            value: true,
                                            message: "Introduzca el nombre"
                                        },
                                        minLength: {
                                            value: 2,
                                            message: "Demasiado corto"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Demasiado largo"
                                        }
                                    })
                                }
                            />
                            {
                                errors.firstName
                                &&
                                (
                                    <div className="error-message text-danger">{errors.firstName.message}</div>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFieldlastName">Apellidos</label>
                            <input
                                type="text"
                                id="inputFieldlastName"
                                className="form-control form-control-sm"
                                name="lastName"
                                aria-describedby="Last name"
                                ref={
                                    register({
                                        required: {
                                            value: true,
                                            message: "Introduzca el apellido"
                                        },
                                        minLength: {
                                            value: 2,
                                            message: "Demasiado corto"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Demasiado largo"
                                        }
                                    })
                                }
                            />
                            {
                                errors.lastName
                                &&
                                (
                                    <div className="error-message text-danger">{errors.lastName.message}</div>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFieldEmailAddress">Dirección de correo electrónico</label>
                            <input
                                type="email"
                                id="inputFieldEmailAddress"
                                className="form-control form-control-sm"
                                name="email"
                                aria-describedby="Email address"
                                ref={
                                    register({
                                        required: {
                                            value: true,
                                            message: "Por favor, introduzca la dirección de correo electrónico"
                                        },
                                        pattern: {
                                            value: '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/',
                                            message: "Esta no parece una dirección de correo electrónico válida, por favor, inténtelo de nuevo"                                   }
                                    })
                                }
                            />
                            {
                                errors.email
                                &&
                                (
                                    <div className="error-message text-danger">{errors.email.message}</div>
                                )
                            }
                            {
                                tryLogin &&
                                (
                                    <div>
                                        <small className="text-info">This email address is already exist. Try another email address or
                                            &nbsp;<Link to="/login">Login</Link>
                                        </small>
                                    </div>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFieldPassword">Contraseña</label>
                            <input
                                type="password"
                                id="inputFieldPassword"
                                className="form-control form-control-sm"
                                name="password"
                                aria-describedby="Password"
                                ref={register({
                                    required: "Introducir la contraseña",
                                    minLength: {
                                      value: 8,
                                      message: "La contraseña debe tener al menos 8 caracteres"
                                    }
                                  })}
                            />
                            { errors.password && (<div className="error-message text-danger">{errors.password.message}</div>) }
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputFieldConfirmPassword">Confirmar contraseña</label>
                            <input
                                type="password"
                                id="inputFieldConfirmPassword"
                                className="form-control form-control-sm"
                                name="confirmPassword"
                                aria-describedby="Confirm Password"
                                ref={register({
                                    validate: value =>
                                      value === password.current || "La contraseña no coincide"
                                  })}
                            />
                            { errors.confirmPassword && (<div className="error-message text-danger">{errors.confirmPassword.message}</div>) }
                        </div>
                        <div className="row">
                            {/* <div className="col">
                                <button className="btn btn-sm btn-link compact-link" onClick={() => props.levelDown()}>
                                    Change account type
                                </button>
                            </div> */}
                            <div className="col text-right">
                                <button className="btn btn-success btn-sm">
                                    {
                                        !isLoading ?
                                            <span className="">Siguiente {isLoading}</span>
                                            :
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default PersonalDetailsForm;