import CryptoJS from "crypto-js";
import Service from './Service'
class AccountService extends Service {
    async storePersonalDetails(details, callback) {
        details.password = CryptoJS.MD5(details.password).toString();
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/addPersonalDetails`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(details)
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data) => {
                callback(data);
            })
    }

    async resetPassword(details, callback) {
        details.newPassword = CryptoJS.MD5(details.newPassword).toString();
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/passwordReset`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(details)
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data) => {
                callback(data);
            })
    }

    async storeBillingDetails(billingDetails, callback) {
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/addBillingDetails`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(billingDetails)
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data) => {
                callback(data);
            })
    }

    async updateBillingDetails(billingDetails, callback) {
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/updateBillingDetails`,
            {
                method: "PUT",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(billingDetails)
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data) => {
                callback(data);
            })
    }

    async verifyAccount(verificationCode, callback) {
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/verificationCode/${verificationCode}/${localStorage.getItem("rToken")}`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.text();
            }).then((data) => {
                callback(data);
            })
    }

    async recoverAccount(requestPacket, callback) {
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/accountRecovery`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(requestPacket)
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data) => {
                callback(data);
            })
    }

    async getBillingDetails(callback) {
        fetch(`${process.env.REACT_APP_ACCESS_POINT}t_user/getBillingDetails`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "accept-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            }).then((response) => {
                if(response.status == '401'){
                    localStorage.removeItem("token");
                    alert("Session Expire, Please login again.");
                    window.location = '/login';
                    return;
                }
                if(!response.ok){
                    this.handleResponseError(response);
                }
                return response.json();
            }).then((data) => {
                callback(data);
            })
    }
}
export default AccountService;