import DeadTableRow from "../DeadTableRow";
import { Link } from "react-router-dom";
import $ from 'jquery';
// import NoDataBanner from "../banners/NoDataBanner";
import QueryInfoPanel from "../panels/QueryInfoPanel";
import { useContext, useEffect, useState } from "react";
import SuppportService from "../../services/SupportService";
import AuthContext from "../../context/AuthContext";
import PaginationPoints from './../../components/other/PaginationPoint'

const QueryTable = (props) => {
    const { auth } = useContext(AuthContext);
    const [fetchType, setFetchType] = useState(2);
    const [queryList, setQueryList] = useState({
        totalRecords: 0,
        pageNo: 1,
        rows: 0,
        data: [
        ]
    });
    useEffect(() => {
        props.setModuleHeading("Previous Queries");
        let request = {
            pageNo: queryList.pageNo,
            userId: auth.userData.accountType === 1 ? 2 : 0
        }
        let supportService = new SuppportService();
        supportService.getAllQuery(request, (data) => {
            //console.log('Msg from Query Table ',data)
            setQueryList(data);
        });
    }, []);
    const { totalRecords, pageNo, rows, data } = queryList;
    const deadRows = [];
    if (data.length < rows) {
        for (let index = data.length; index < 10; index++) {
            deadRows.push(<DeadTableRow key={"dead_row_queryList_" + index} index={index + 1} />);
        }
    }
    const holdMenu = (event) => {
        event.preventDefault();
        $(event.target).parents(".foldable").find(".folded-content").slideToggle(200);
    }
    const filterQuery = (type) => {
        if (fetchType !== type) {
            setFetchType(type);
            const fi = auth.userData.accountType === 1 ? type : 0;
            let request = {
                pageNo: 1,
                userId: fi
            }
            let supportService = new SuppportService();
            supportService.getAllQuery(request, (data) => {
                setQueryList(data);
            });
        }
    }
    const changeSet = (setNumber) => {
        if (pageNo !== setNumber) {
            let request = {
                pageNo: setNumber,
                userId: fetchType
            }
            let supportService = new SuppportService();
            supportService.getAllQuery(request, (data) => {
                setQueryList(data);
            });
        }
    }
    
    const paginationPoints = [];
    if (totalRecords > rows) {
        for (let index = Math.max(1, pageNo - 5); index <= Math.min(pageNo + 5, Math.ceil(totalRecords / rows)); index++) {
            let isActive = (pageNo === index) ? "active" : ""
            paginationPoints.push(<PaginationPoints onClick={changeSet.bind(null, index)} isActive={"" + isActive} setNumber={index} />);
        }
    }
    return (
        <div className="table-container">
            <div className="row">
                <div className="col-md-3">
                    {/* <input className="form-control form-control-sm" type="text" placeholder="Search here" /> */}
                </div>
                <div className="col text-right">
                    {
                        (auth.userData.accountType === 2 || auth.userData.accountType === 3 || auth.userData.accountType === 4) &&
                        (
                            <ul className="module-options">
                                <li>
                                    <Link to="/adblock/support/newquery" className="btn btn-sm btn-success">Enviar nueva consulta</Link>
                                </li>
                            </ul>
                        )
                    }
                    {
                        (auth.userData.accountType === 1) &&
                        (
                            <ul className="module-options">
                                <li>
                                    <div class="switch-field">
                                        <input onClick={() => filterQuery(2)} checked={fetchType === 2} type="radio" id="inputFieldSubAdmin" name="queryToggle" />
                                        <label htmlFor="inputFieldSubAdmin">Sub Admin</label>
                                        <input onClick={() => filterQuery(3)} checked={fetchType === 3} type="radio" id="inputFieldBrand" name="queryToggle" />
                                        <label htmlFor="inputFieldBrand">Brand</label>
                                    </div>
                                </li>
                            </ul>
                        )
                    }
                </div>
            </div>
            <div className="table">
                <div className="table-header">
                    <div className="row">
                        <div className="col-md-1">N.º ref.</div>
                        <div className="col-md-6">Asunto</div>
                        <div className="col-md-3">Fecha</div>
                        <div className="col-md-2">Estado</div>
                    </div>
                </div>
                <div className="table-option-list"></div>
                <div className="table-body">
                    {
                        queryList.data.map((ele, index) => {

                            return (
                                <div key={"table_row_index" + index} className="table-row foldable">
                                    <div onClick={(e) => holdMenu(e)} key={"queryList_" + index} className="row preview-row">
                                        <div className="col-md-1">
                                            <label>{index + 1}</label>
                                        </div>
                                        <div className="col-md-6 placement-value">
                                            {/* put ele type icon here */}
                                            <label title={ele.query_title}>
                                                {
                                                    ele.query_title.length < 41 ? ele.query_title : ele.query_title.substr(0, 40) + "..."
                                                }
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label>{new Date(ele.generated_on).toLocaleString()}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <label>
                                                {
                                                    ele.resolved_on !== null ?
                                                        (<small className="badge badge-success">Resuelto en {ele.status.toUpperCase()}</small>)
                                                        :
                                                        (<small className="badge badge-danger">Sin resolver aún</small>)
                                                }
                                            </label>
                                        </div>

                                    </div>
                                    <div className="foldable-container">
                                        <div className="folded-content">
                                            <QueryInfoPanel query={ele} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {/* Adding dead rows */}
                    {
                        deadRows
                    }
                </div>
                <div className="table-footer">
                    <div className="row pagination">
                        <div className="col">
                            <ul >
                                {
                                    totalRecords > rows &&
                                    <li>
                                        <button onClick={changeSet.bind(null, 1)} className="btn btn-link">primera</button>
                                    </li>
                                }
                                {
                                    paginationPoints
                                }
                                {
                                    totalRecords > rows &&
                                    <li>
                                        <button onClick={changeSet.bind(null, Math.ceil(totalRecords / rows))} className="btn btn-link">última</button>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default QueryTable;